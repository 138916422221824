import { FrontendClient } from '@telus/frontend-common';
import {
  ConfigApi,
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { ApiRef, createApiRef } from '@backstage/core-plugin-api';

/**
 * {@link @backstage/core-plugin-api#ApiRef}
 */

export const docsViewerApiRef: ApiRef<DocsViewerApi> =
  createApiRef<DocsViewerApi>({
    id: 'plugin.docsviewer.service',
  });

export interface DocsViewerApi {
  getRepository(repoName: string): Promise<any>;
  getRepositoryDocs(repoName: string): Promise<any>;
  getRepositoryDoc(docUrl: string): Promise<any>;
  updateRepositoryDoc(docUrl: string, doc: any): Promise<any>;
  getImage(repoName: string, imagePath: string): Promise<any>;
  getRepositoryHtmlPage(htmlUrl: string): Promise<any>;
  getRepositoryHtmlPages(repoName: string): Promise<any>;
  clearCache(reponame?: string): void;
}

type deps = {
  discoveryApi: DiscoveryApi;
  configApi: ConfigApi;
  identityApi: IdentityApi;
};

export class DocsViewerClient extends FrontendClient implements DocsViewerApi {
  protected readonly configApi: ConfigApi;

  constructor(deps: deps) {
    super({
      discoveryApi: deps.discoveryApi,
      identityApi: deps.identityApi,
      defaultPlugin: 'docs-viewer',
    });

    this.configApi = deps.configApi;
  }

  async getRepository(repoName: string) {
    return this.getRequired(`/repos/${repoName}`);
  }

  async getRepositoryDocs(repoName: string) {
    return this.getRequired(`/repos/${repoName}/docs`);
  }

  async getRepositoryDoc(docUrl: string) {
    return this.postRequired(`/repos/doc`, {
      docUrl,
    });
  }

  async updateRepositoryDoc(docUrl: string, content: any) {
    return this.putRequired(`/repos/doc`, {
      docUrl,
      content,
      savedBy: await this.identityApi.getProfileInfo(),
    });
  }

  async getRepositoryHtmlPage(htmlUrl: string) {
    return this.postRequired(`/repos/html`, {
      htmlUrl,
    });
  }

  async getRepositoryHtmlPages(repoName: string) {
    return this.getRequired(`/repos/${repoName}/html`);
  }

  async getImage(repoName: string, imagePath: string) {
    return this.getRequired(`/repos/${repoName}/image/${imagePath}`);
  }

  async clearCache(repoName: string) {
    return this.postRequired(`/repos/${repoName}/clear-cache`, {});
  }
}
