export const t: Record<string, any> = {
  name: {
    en: 'Name',
    fr: 'Nom',
  },
  reason: {
    en: 'Reason',
    fr: 'Raison',
  },
  type: {
    en: 'Type',
    fr: 'Type',
  },
  startTime: {
    en: 'Start time',
    fr: 'Heure de début',
  },
  endTime: {
    en: 'End time',
    fr: 'Heure de fin',
  },
  widgetTitle: {
    en: 'Dynatrace: Problems',
    fr: 'Dynatrace: Problèmes',
  },
  widgetSubtitle: {
    en: 'Quick overview of problems live in dynatrace',
    fr: 'Aperçu rapide et en direct des problèmes dans dynatrace',
  },
  dynatraceMonitor: {
    en: 'Dynatrace Monitor',
    fr: 'Moniteur Dynatrace',
  },
  dynatraceHeaderSubtitle: {
    en: 'Check dynatrace statuses',
    fr: 'Vérifier les status dynatrace',
  },
  overview: {
    en: 'Overview',
    fr: 'Vue d\'ensemble',
  },
  repositories: {
    en: 'Repositories',
    fr: 'Répertoires',
  },
  problems: {
    en: 'Problems',
    fr: 'Problèmes',
  },
  registeredEntities: {
    en: 'Registered entities from catalog,',
    fr: 'Entités enregistrées depuis le catalogue,'
  },
  clickHereToRegister: {
    en: 'click here to register',
    fr: 'cliquez ici pour enregistrer'
  },
  kind: {
    en: 'Kind',
    fr: 'Type',
  },
  totalCount: {
    en: 'Total count',
    fr: 'Nombre total',
  },
  notLinked: {
    en: 'Not linked',
    fr: 'Non lié',
  },
  linked: {
    en: 'Linked',
    fr: 'Lié',
  },
  component: {
    en: 'Component',
    fr: 'Composant',
  },
  api: {
    en: 'API',
    fr: 'API',
  },
  library: {
    en: 'Library',
    fr: 'Librairie',
  },
  resource: {
    en: 'Resource',
    fr: 'Ressource',
  },
  service: {
    en: 'Service',
    fr: 'Service',
  },
  website: {
    en: 'Website',
    fr: 'Site web',
  },
  system: {
    en: 'System',
    fr: 'Système',
  },
  problemsFound: {
    en: 'problems found',
    fr: 'problèmes trouvés',
  },
  noProblemsFound: {
    en: 'No problems found',
    fr: 'Aucun problème trouvé',
  },
  noProblemsToReport: {
    en: 'No problems to report',
    fr: 'Aucun problème à signaler',
  },
  last2Hours: {
    en: 'Last 2 hours',
    fr: '2 dernières heures',
  },
  viewEntityInDynatrace: {
    en: 'View entity in dynatrace',
    fr: 'Voir l\'entité dans dynatrace',
  },
  title: {
    en: 'Title',
    fr: 'Titre',
  },
  severity: {
    en: 'Severity',
    fr: 'Sévérité',
  },
  status: {
    en: 'Status',
    fr: 'Status',
  },
  rootCause: {
    en: 'Root cause',
    fr: 'Cause première',
  },
  affected: {
    en: 'Affected',
    fr: 'Affecté',
  },
  onGoing: {
    en: 'ongoing',
    fr: 'En cours',
  },
  open: {
    en: 'Open',
    fr: 'Ouvert',
  },
  closed: {
    en: 'Closed',
    fr: 'Fermé',
  },
  all: {
    en: 'All',
    fr: 'Tous',
  },
  list: {
    en: 'List',
    fr: 'Liste',
  },
  notLinkedToDynatraceEntity: {
    en: 'Not linked to Dynatrace entity',
    fr: 'Non lié à une entité Dynatrace',
  },
  synthetics: {
    en: 'Synthetics',
    fr: 'Synthétique',
  },
  recentActivity: {
    en: 'Recent Activity for Monitor',
    fr: 'Activité récente pour le moniteur',
  },
  viewSyntheticInDynatrace: {
    en: 'View this Synthetic in Dynatrace',
    fr: 'Voir ce synthétique dans Dynatrace',
  },
}
