import { createApiRef } from '@backstage/core-plugin-api';

export const cloudDeployApiRef = createApiRef<CloudDeployApi>({
  id: 'plugin.googleclouddeploy.service',
});

export type Stage = {
  targetId: string;
  profiles: Array<string>;
}

export type DeliveryPipeline = {
  name: string;
  serialPipeline: {
    stages: Array<Stage>
  }
}

export type Target = {
  targetId: string;
  description: string;
  createTime: string;
  updateTime: string;
  gke: {
    cluster: string;
  }
}

export type GetDeliveryPipelineRequest = {
  projectId: string;
  location: string;
  deliveryPipeline: string;
};

export type GetTargetRequest = {
  projectId: string;
  location: string;
  target: string;
};

export type GetTargetsRequest = {
  projectId: string;
  location: string;
  targets: Array<string>;
};

export type CloudDeployApi =  {
  /**
   * Gets details of a single DeliveryPipeline.
   * @param {GetDeliveryPipelineRequest} params - The {@link GetDeliveryPipelineRequest} parameters
   * @returns {Promise<DeliveryPipeline>} - A Promise that resolves to the {@link DeliveryPipeline} details
   */
  getDeliveryPipeline(params: GetDeliveryPipelineRequest): Promise<DeliveryPipeline>;
  /**
   * Gets details of a single Target.
   * @param {GetTargetRequest} params - The {@link GetTargetRequest} parameters
   * @returns {Promise<Target>} - A Promise that resolves to the {@link Target} details
   */
  getTarget(params: GetTargetRequest): Promise<Target>;
  /**
   * Gets details of multiple Targets.
   * @param {GetTargetsRequest} params - The {@link GetTargetsRequest} parameters
   * @returns {Promise<Array<Target>>} - A Promise that resolves to an array of {@link Target} details
   */
  getTargets(params: GetTargetsRequest): Promise<Array<Target>>;
}
