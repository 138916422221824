import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api'

import { rootRouteRef } from './routes'

export const tdrmPlugin = createPlugin({
  id: 'tdrm',
  routes: {
    root: rootRouteRef,
  },
})

export const TdrmPage = tdrmPlugin.provide(
  createRoutableExtension({
    name: 'TdrmPage',
    component: () =>
      import('./components/Table').then(m => m.IssueTable),
    mountPoint: rootRouteRef,
  }),
)