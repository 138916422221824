import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const tascFrontendPlugin = createPlugin({
  id: 'tasc-frontend',
  routes: {
    root: rootRouteRef,
  },
});

export const TascFrontendPage = tascFrontendPlugin.provide(
  createRoutableExtension({
    name: 'TascFrontendPage',
    component: () =>
      import('./components/Main').then(m => m.Main),
    mountPoint: rootRouteRef,
  }),
);

