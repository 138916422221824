import { FrontendClient } from '@telus/frontend-common';
import {
  ApiRef,
  createApiRef,
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api';

/**
 * {@link @backstage/core-plugin-api#ApiRef}
 */

export const landingPageApiRef: ApiRef<LandingPageApi> =
  createApiRef<LandingPageApi>({
    id: 'plugin.aactemplatelanding.service',
  });

export interface LandingPageApi {
  getLandingPage(repo: string, path: string, ghToken: string): Promise<any>;
}

type deps = {
  discoveryApi: DiscoveryApi;
  identityApi: IdentityApi;
};

export class LandingPageClient extends FrontendClient implements LandingPageApi {

  constructor(deps: deps) {
    super({
      discoveryApi: deps.discoveryApi,
      identityApi: deps.identityApi,
      defaultPlugin: 'aac-template-landing',
    });
  }

  async getLandingPage(repo: string, path: string, ghToken: string) {
    const queryParams = new URLSearchParams({ path }).toString();
    return this.getRequired(
      `/github/getFile/${repo}?${queryParams}`,
      this.defaultPlugin,
      {
        headers: {
          Token: ghToken
        }
      }
    );
  }
}