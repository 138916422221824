import { AdrFilePathFilterFn } from '@backstage/plugin-adr-common';

export const telusCustomAdrFilterFn: AdrFilePathFilterFn = (
  path: string,
): boolean => {
  if (
    path === '0000-adr-template.md' ||
    path === 'adr-template.md' ||
    path === 'README.md'
  ) {
    return false;
  }
  // Return all md files with the exception of template files.
  return /.+\.md$/.test(path); // default: /^(decided-adrs\/)?\d{4}-.+\.md$/.test(path);
};

export function telusCustomContentTransformation(content: string): string {
  try {
    // transform the content to read what is in the middle of the first --- and  second --- if was found return the content deleting this part, if not return the full content
    const firstSeparator = content.indexOf('---');
    const secondSeparator = content.indexOf('---', firstSeparator + 1);
    if (firstSeparator > -1 && secondSeparator > -1) {
      const segment = content.substring(firstSeparator + 3, secondSeparator);
      const lines = segment.split('\n');

      const headerValues = [];
      const values = [];
      for (let i = 0; i < lines.length; i++) {
        const line = lines[i].split(':');
        if (line.length > 1) {
          headerValues.push(line[0].trim());
          values.push(line[1].trim());
        }
      }
      const headerRow = `| ${headerValues.join(' | ')} |`;
      const separatorRow = `|${' - | '.repeat(headerValues.length)}`;
      const valuesRows = `| ${values.join(' | ')} |`;
      const table = `${headerRow}\n${separatorRow}\n${valuesRows}`;
      return `${content.substring(
        0,
        firstSeparator,
      )}\n${table}\n${content.substring(secondSeparator + 3)}`;
    }

    return content;
  } catch (error) {
    return content;
  }
}
