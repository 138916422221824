import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMobileContext } from './MobileNavBar';
import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { navigateLink } from '../navHelpers';

const useStyles = makeStyles(theme => ({
  mobileCard: {
    backgroundColor: theme.palette.background.paper,
    margin: '16px',
    padding: '16px',
    maxWidth: '300px',
  },
  linkButton: {
    textTransform: 'initial',
    fontSize: '16px',
    color: theme.palette.primary.main,
    padding: '8px 0 0 0',

    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'initial',
    },
  },
}));

interface MobileNavCardProps {
  title: string;
  content: string;
  linkTitle: string;
  link: string;
}

export const MobileNavCard = (props: MobileNavCardProps) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { setDropdown } = useMobileContext();

  return (
    <Box className={classes.mobileCard} borderRadius={8}>
      <Typography
        variant="subtitle1"
        style={{ fontWeight: '700', paddingBottom: '8px' }}
      >
        {props.title}
      </Typography>
      <Typography variant="body1">{props.content}</Typography>
      <Button
        className={classes.linkButton}
        variant="text"
        component="a"
        href={props.link}
        onClick={navigateLink(setDropdown, navigate)}
      >
        {props.linkTitle} <ArrowForwardIcon fontSize="small" />
      </Button>
    </Box>
  );
};
