import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'api-tools',
});

// @see: https://backstage.io/docs/plugins/composability#subroutes
export const apiFdFormRouteRef = createSubRouteRef({
  id: 'api-tools-details',
  parent: rootRouteRef,
  path: '/api-front-door',
});
