import React from 'react';

import { useMobileContext } from './MobileNavBar';
import { Typography, makeStyles } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { navigateLink } from '../navHelpers';

const useStyles = makeStyles(theme => ({
  mobileLink: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    fontSize: '16px',
    padding: '16px',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'initial',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
    },
  },
}));

interface MobileNavLinkProps {
  title?: string;
  link?: string;
}

export const MobileNavLink = (props: MobileNavLinkProps) => {
  const classes = useStyles();

  const navigate = useNavigate();
  const { setDropdown } = useMobileContext();

  return (
    <Typography
      component="a"
      href={props.link}
      onClick={navigateLink(setDropdown, navigate)}
      className={classes.mobileLink}
    >
      {props.title}
    </Typography>
  );
};
