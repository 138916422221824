import React from 'react';
import { Box } from '@material-ui/core';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  className?: any;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, className, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      className={className}
      tabIndex={-1}
    >
      {value === index && (
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          {children}
        </Box>
      )}
    </div>
  );
};
