import {
  createApiRef,
  IdentityApi,
  ConfigApi,
  DiscoveryApi,
} from '@backstage/core-plugin-api';
import { FrontendClient } from '@telus/frontend-common'

type deps = {
  discoveryApi: DiscoveryApi
  configApi: ConfigApi
  identityApi: IdentityApi
}

export const securityMetricsApiRef = createApiRef<SecurityMetricsClient>({
  id: 'plugin.security-metrics.service',
});

export class SecurityMetricsClient extends FrontendClient {
  constructor(deps: deps) {
    super({
      discoveryApi: deps.discoveryApi,
      identityApi: deps.identityApi,
      defaultPlugin: 'security-metrics',
    })
  }

  async getHealth(): Promise<any> {
    return this.getRequired('/health')
  }

  async getSecurityMetricsQuery(): Promise<any> {
    return this.getRequired('/query')
  }
}
