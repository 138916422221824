import { FrontendClient } from '../classes';
import {
  ConfigApi,
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { ApiRef, createApiRef } from '@backstage/core-plugin-api';

/**
 * {@link @backstage/core-plugin-api#ApiRef}
 */

export const ownershipProviderApiRef: ApiRef<OwnershipProviderApi> =
  createApiRef<OwnershipProviderApi>({
    id: 'plugin.ownership-provider.service',
  });

export interface OwnershipProviderApi {
  getUsers: () => Promise<any>;
  getTeams: () => Promise<any>;
  getOwnership: () => Promise<any>;
  getRepositories: () => Promise<any>;
  getUserEntity: (key: string) => Promise<any>;
  updateUserEntity: (key: string, data: any) => Promise<any>;
  getTeamEntity: (key: string) => Promise<any>;
  updateTeamEntity: (key: string, data: any) => Promise<any>;
  getRepositoryEntity: (key: string) => Promise<any>;
  updateRepositoryEntity: (key: string, data: any) => Promise<any>;
  getRepositoryEntityByName: (name: string) => Promise<any>;
  updateRepositoryEntityByName: (name: string, data: any) => Promise<any>;
}

type deps = {
  discoveryApi: DiscoveryApi;
  configApi: ConfigApi;
  identityApi: IdentityApi;
};

export class OwnershipProviderClient
  extends FrontendClient
  implements OwnershipProviderApi
{
  protected readonly configApi: ConfigApi;

  constructor(deps: deps) {
    super({
      discoveryApi: deps.discoveryApi,
      identityApi: deps.identityApi,
      defaultPlugin: 'ownership',
    });

    this.configApi = deps.configApi;
  }

  async getUsers() {
    return await this.getRequired('/users');
  }

  async getTeams() {
    return await this.getRequired('/teams');
  }

  async getOwnership() {
    return await this.getRequired('/ownership');
  }

  async getRepositories() {
    return await this.getRequired('/simplify');
  }

  async getUserEntity(key: string) {
    return await this.postRequired('/user/read', { key });
  }

  async updateUserEntity(key: string, data: any) {
    return await this.postRequired('/user/update', { key, data });
  }

  async getTeamEntity(key: string) {
    return await this.postRequired('/team/read', { key });
  }

  async updateTeamEntity(key: string, data: any) {
    return await this.postRequired('/team/update', { key, data });
  }

  async getRepositoryEntity(key: string) {
    return await this.postRequired('/repository/read', { key });
  }

  async updateRepositoryEntity(key: string, data: any) {
    return await this.postRequired('/repository/update', { key, data });
  }

  async getRepositoryEntityByName(name: string) {
    return await this.postRequired('/repository/read-by-name', { name });
  }

  async updateRepositoryEntityByName(name: string, data: any) {
    return await this.postRequired('/repository/update-by-name', {
      name,
      data,
    });
  }
}
