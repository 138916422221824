import { AnalyticsApi, AnalyticsEvent } from '@backstage/core-plugin-api';

import alloyAnalytics from '@telus/alloy-analytics';
import { debounce } from 'lodash';

export class AlloyAnalytics implements AnalyticsApi {
  constructor() {
    this.search = debounce(this.search.bind(this), 1000);
  }

  static fromConfig() {
    const getLanguageFromQueryParams = (queryParams: URLSearchParams): string => {
      const langParam = queryParams.get('lang');
      return langParam === 'en' || langParam === 'fr' ? langParam : 'en';
    };
    const queryParams = new URLSearchParams(window.location.search);
    const language = getLanguageFromQueryParams(queryParams);
    alloyAnalytics.config({
      defaultValues: {
        webPageDetails: {
          lob: 'platform',
          site: 'developers',
          siteGenerator: 'simplify',
          userAccountType: '',
          pageLanguage: language,
        },
      },
    });
    return new AlloyAnalytics();
  }

  search(event: AnalyticsEvent) {
    alloyAnalytics
      .event('search')
      .search({
        term: event.subject.replace(/ /g, '_'),
        numberOfResults: event.value,
      })
      .fire();
  }

  captureEvent(event: AnalyticsEvent) {
    // declaring variables to dynamically adjust pageType
    const alloyPageType = event.context.pluginId.replace(/-/g, '_');
    const alloySubjectType = event.subject;
    const alloyRouteRef = event.context.routeRef; 
    const alloyRepoName = event.attributes?.reponame;

    // fires whenever the URL changes - originates from RouteTracker.tsx
    if (event.action === 'navigate') {
      if (alloyPageType === 'home' && alloySubjectType === '/' && alloyPageType) {
        alloyAnalytics
          .event('pageLoaded')
          .webPageDetails({
            pageType: 'home',
            pageName: ['telus', 'simplify']
              .concat(window.location.pathname.split('/').filter(Boolean))
              .join('>')
              .replace(/-/g, '_'),
          })
          .fire();
      } else if (alloyPageType === 'search') {
        alloyAnalytics
          .event('pageLoaded')
          .webPageDetails({
            pageType: 'overview',
            pageName: ['telus', 'simplify']
              .concat(window.location.pathname.split('/').filter(Boolean))
              .join('>')
              .replace(/-/g, '_'),
          })
          .fire();
      } else if (alloyPageType === 'catalog') {
        alloyAnalytics
          .event('pageLoaded')
          .webPageDetails({
            pageType: 'tool',
            pageName: ['telus', 'simplify']
              .concat(window.location.pathname.split('/').filter(Boolean))
              .join('>')
              .replace(/-/g, '_'),
          })
          .fire();
      } else if (
        alloyPageType === 'api_docs' ||
        alloyPageType === 'tech_radar' ||
        alloyPageType === 'graphiql' ||
        alloyPageType === 'external_health_check_frontend' ||
        alloyPageType === 'scaffolder' ||
        alloyPageType === 'skill_exchange' ||
        alloyPageType === 'ai_translation_frontend' ||
        alloyPageType === 'mkdocs_creator' ||
        alloyPageType === 'team_skills_frontend' ||
        alloyPageType === 'dynatrace' ||
        alloyPageType === 'api_tools' ||
        alloyPageType === 'qeta' ||
        alloyPageType === 'gpt_frontend' ||
        alloyPageType === 'spoc' 
      ) {
        alloyAnalytics
          .event('pageLoaded')
          .webPageDetails({
            pageType: 'tool',
            pageName: ['telus', 'simplify']
              .concat(window.location.pathname.split('/').filter(Boolean))
              .join('>')
              .replace(/-/g, '_'),
          })
          .fire();
      } else if (alloyPageType === 'user_settings') {
        alloyAnalytics
          .event('pageLoaded')
          .webPageDetails({
            pageType: 'settings',
            pageName: ['telus', 'simplify']
              .concat(window.location.pathname.split('/').filter(Boolean))
              .join('>')
              .replace(/-/g, '_'),
          })
          .fire();
      } else if (
        alloySubjectType === '/external' ||
        alloySubjectType === '/capability-tree'
      ) {
        alloyAnalytics
          .event('pageLoaded')
          .webPageDetails({
            pageType: 'landing',
            pageName: ['telus', 'simplify']
              .concat(window.location.pathname.split('/').filter(Boolean))
              .join('>')
              .replace(/-/g, '_'),
          })
          .fire();
      } else if (alloyRouteRef === 'techdocs:index-page') {
        alloyAnalytics
          .event('pageLoaded')
          .webPageDetails({
            pageType: 'overview',
            pageName: ['telus', 'simplify']
              .concat(window.location.pathname.split('/').filter(Boolean))
              .join('>')
              .replace(/-/g, '_'),
          })
          .fire();
      } else if (alloyRouteRef === 'techdocs:reader-page') {
        alloyAnalytics
          .event('pageLoaded')
          .webPageDetails({
            pageType: 'article',
            pageName: ['telus', 'simplify']
              .concat(window.location.pathname.split('/').filter(Boolean))
              .join('>')
              .replace(/-/g, '_'),
          })
          .fire();
      } else if (alloyPageType === 'docs_viewer_frontend' && !alloyRepoName) {
        alloyAnalytics
          .event('pageLoaded')
          .webPageDetails({
            pageType: 'landing',
            pageName: ['telus', 'simplify']
              .concat(window.location.pathname.split('/').filter(Boolean))
              .join('>')
              .replace(/-/g, '_'),
          })
          .fire();
      } else if (alloyPageType !== 'docs_viewer_frontend') {
        alloyAnalytics
          .event('pageLoaded')
          .webPageDetails({
            pageType: 'error',
            pageName: ['telus', 'simplify']
              .concat(window.location.pathname.split('/').filter(Boolean))
              .join('>')
              .replace(/-/g, '_'),
          })
          .fire();
      }
    }

    // fires when a user searches from core search components (not documentation)
    if (event.action === 'search') {
      this.search(event);
    }
  }
}
