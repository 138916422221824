import {
  BackstageTheme,
  createTheme,
  genPageTheme,
  lightTheme,
  shapes,
} from '@backstage/theme';
import { BackstageOverrides } from '@backstage/core-components';

import * as alliumPalette from '@telus-uds/palette-allium/build/web/palette';
import * as alliumTheme from '@telus-uds/theme-allium/build/theme';

const { color } = alliumPalette;
const { Button, TextInput, IconButton, Icon } = alliumTheme.components;
shapes.blank = `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='1368' height='400' fill='none'%3e%3cmask id='a' width='1368' height='401' x='0' y='0' maskUnits='userSpaceOnUse'%3e%3cpath fill='url(%23paint0_linear)' d='M437 116C223 116 112 0 112 0h1256v400c-82 0-225-21-282-109-112-175-436-175-649-175z'/%3e%3cpath fill='url(%23paint1_linear)' d='M1368 400V282C891-29 788 40 711 161 608 324 121 372 0 361v39h1368z'/%3e%3cpath fill='url(%23paint2_linear)' d='M1368 244v156H0V94c92-24 198-46 375 0l135 41c176 51 195 109 858 109z'/%3e%3cpath fill='url(%23paint3_linear)' d='M1252 400h116c-14-7-35-14-116-16-663-14-837-128-1013-258l-85-61C98 28 46 8 0 0v400h1252z'/%3e%3c/mask%3e%3cg mask='url(%23a)'%3e%3cpath fill='white' d='M-172-98h1671v601H-172z'/%3e%3c/g%3e%3cdefs%3e%3clinearGradient id='paint0_linear' x1='602' x2='1093.5' y1='-960.5' y2='272' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='white'/%3e%3cstop offset='1' stop-color='white' stop-opacity='0'/%3e%3c/linearGradient%3e%3clinearGradient id='paint1_linear' x1='482' x2='480' y1='1058.5' y2='70.5' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='white'/%3e%3cstop offset='1' stop-color='white' stop-opacity='0'/%3e%3c/linearGradient%3e%3clinearGradient id='paint2_linear' x1='424' x2='446.1' y1='-587.5' y2='274.6' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='white'/%3e%3cstop offset='1' stop-color='white' stop-opacity='0'/%3e%3c/linearGradient%3e%3clinearGradient id='paint3_linear' x1='587' x2='349' y1='-1120.5' y2='341' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='white'/%3e%3cstop offset='1' stop-color='${color.purpleTelus}' stop-opacity='0'/%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e")`;

// const createCustomOverridesDark = (): BackstageOverrides => {
//   return {
//     BackstageDependencyGraphNode: {
//       node: {
//         stroke: '#262626',
//       },
//     },
//     MuiTableCell: {
//       root: {
//         wordBreak: 'normal',
//       },
//     },
//     MuiLink: {
//       root: {
//         color: color.white,
//         marginLeft: 0,
//       },
//     },
//     MuiChip: {
//       root: {
//         backgroundColor: color.greyShuttle,
//       },
//     },
//     MuiTableRow: {
//       root: {
//         backgroundColor: color.greyCharcoal,
//       },
//     },
//     MuiButton: {
//       contained: {
//         ...Button.tokens,
//         textTransform: 'initial'
//       },
//       textPrimary: {
//         ...Button.tokens,
//         backgroundColor: color.greyThunder,
//         color: color.white,
//         textTransform: 'initial'
//       },
//       outlinedPrimary: {
//         ...Button.tokens,
//         backgroundColor: color.greyThunder,
//         color: color.white,
//         textTransform: 'initial'
//       },
//     },
//     MuiButtonGroup: {
//       groupedOutlined: {
//         borderRadius: '0px',
//         fontSize: '0.875rem',
//         padding: '6.4px 32px',
//         lineHeight: '1.75',
//       },
//     },
//     BackstageTabs: {
//       root: {
//         ...Tabs.tokens,
//       },
//     },
//     MuiInput: {
//       root: {
//         ...TextInput.tokens,
//         backgroundColor: color.greyCharcoal,
//         color: color.white,
//       },
//     },
//     MuiOutlinedInput: {
//       root: {
//         ...TextInput.tokens,
//         backgroundColor: color.greyCharcoal,
//         color: color.white,
//       },
//     },
//     MuiIconButton: {
//       root: {
//         ...IconButton.tokens,
//       },
//     },
//     MuiIcon: {
//       root: {
//         ...Icon.tokens,
//       },
//     },
//     BackstageHeader: {
//       header: {
//         zIndex: 9,
//       },
//       title: {
//         fontWeight: 325,
//         fontSize: '36px',
//         height: '40px',
//       },
//       subtitle: {
//         display: 'none'
//       }
//     },
//     BackstageContentHeader: {
//       title: {
//         fontWeight: 400
//       }
//     },
//     BackstageInfoCard: {
//       headerTitle: {
//         fontWeight: 400,
//       }
//     },
//     BackstageTable: {
//       root: {
//         width: '100%'
//       },
//     },
//     BackstageTableHeader: {
//       header: {
//         fontWeight: 400,
//       }
//     },
//     MuiTypography: {
//       button: {
//         textTransform: 'initial',
//       },
//       h5: {
//         fontWeight: 400
//       },
//       subtitle2: {
//         textTransform: 'initial',
//       },
//     },

//     BackstageSidebar: {
//       drawer: {
//         display: 'none'
//       },
//       drawerOpen: {
//         display: 'none'
//       }
//     },
//     BackstageSidebarIntro: {

//     },
//     BackstageSidebarItem: {
//       root: {
//         display: 'none',
//       }
//     },
//     BackstagePage: {
//       root: {
//         overflow: 'hidden'
//       }
//     },
//   };
// };

const createCustomOverrides = (): BackstageOverrides => {
  return {
    MuiTableCell: {
      root: {
        wordBreak: 'normal',
      },
    },
    MuiButton: {
      contained: {
        ...Button.tokens,
        textTransform: 'initial',
      },
      textPrimary: {
        ...Button.tokens,
        textTransform: 'initial',
      },
      outlinedPrimary: {
        ...Button.tokens,
        textTransform: 'initial',
      },
    },
    MuiButtonGroup: {
      groupedOutlined: {
        borderRadius: '0px',
        fontSize: '0.75rem',
        padding: '6.4px 32px',
        lineHeight: '1.75',
      },
    },
    // BackstageTabs: {
    //   root: {
    //     ...Tabs.tokens,
    //   },
    // },
    MuiInput: {
      root: {
        ...TextInput.tokens,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    MuiOutlinedInput: {
      root: {
        ...TextInput.tokens,
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiIconButton: {
      root: {
        ...IconButton.tokens,
      },
    },
    MuiIcon: {
      root: {
        ...Icon.tokens,
      },
    },
    // Tab overrides for Nav
    MuiTab: {
      root: {
        minwidth: 0,
        '@media (min-width: 0px)': {
          minWidth: '110px',
        },
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      },
    },
    // disables the native sidebar nav
    BackstageSidebar: {
      drawer: {
        display: 'none',
      },
      drawerOpen: {
        display: 'none',
      },
    },
    BackstageSidebarItem: {
      root: {
        display: 'none',
      },
    },
    // prevents double scrollbars
    BackstagePage: {
      root: {
        height: '100%',
        overflow: 'hidden',
      },
    },
    // Default Backstage Header, overridden to match UDS/TextHeader
    BackstageHeader: {
      header: {
        backgroundImage: 'unset',
        boxShadow: 'unset',
        background: `var(--background-light-bg-light-grey-athens, #F4F4F7)`,
        zIndex: 9,
      },
      title: {
        fontWeight: 325,
        fontSize: '36px',
        height: '40px',
        color: color.purpleTelus,
      },
      subtitle: {
        display: 'none',
      },
    },
    BackstageHeaderLabel: {
      label: {
        color: color.purpleTelus,
      },
      value: {
        color: color.purpleTelus,
      },
    },
    BackstageContentHeader: {
      title: {
        fontWeight: 400,
      },
    },
    BackstageInfoCard: {
      headerTitle: {
        fontWeight: 400,
      },
    },
    BackstageTable: {
      root: {
        width: '100%',
      },
    },
    BackstageTableHeader: {
      header: {
        // overrides the bolded legend header text in tables
        fontWeight: 400,
      },
    },
    // Override the default typography to match UDS.
    MuiTypography: {
      h1: {
        fontWeight: 300,
        fontSize: '28px',
        lineHeight: '36px',
        '@media (min-width:960px)': {
          fontSize: '40px',
          lineHeight: '48px',
        },
      },
      h2: {
        fontWeight: 400,
        fontSize: '24px',
        lineHeight: '32px',
        '@media (min-width:960px)': {
          fontSize: '28px',
          lineHeight: '36px',
        },
      },
      h3: {
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '28px',
        '@media (min-width:960px)': {
          fontSize: '24px',
          lineHeight: '32px',
        },
      },
      h4: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
      },
      h5: {
        fontWeight: 500,
        // unable to match UDS as backstage components manually use h5 for titles for info cards and tables
        // fontSize: '14px',
        // lineHeight: '28px',
      },
      h6: {
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '16px',
      },
      subtitle2: {
        textTransform: 'initial',
      },
    },
  };
};

// const customDarkTheme = createTheme({
//   palette: {
//     ...darkTheme.palette,
//     primary: {
//       main: color.greenAccessible,
//       light: color.white,
//     },
//     secondary: {
//       main: color.purpleDeluge,
//       light: color.white,
//     },
//     error: {
//       main: color.redDark,
//     },
//     warning: {
//       main: color.amberDark,
//     },
//     info: {
//       main: color.greyMystic,
//     },
//     success: {
//       main: color.greenAccessible,
//     },
//     background: {
//       default: color.greyThunder,
//       paper: color.greyCharcoal,
//     },
//     banner: {
//       info: color.purpleDeluge,
//       error: color.redDark,
//       text: color.greyMystic,
//       link: color.greyShuttle,
//     },
//     errorBackground: color.redDark,
//     warningBackground: color.amberDark,
//     infoBackground: color.greyMystic,
//     navigation: {
//       background: color.greyCharcoal,
//       indicator: color.white,
//       color: color.greyCloud,
//       selectedColor: color.greenTelus,
//     },
//   },
//   defaultPageTheme: 'home',
//   fontFamily: 'Helvetica Now, Helvetica',
//   /* below drives the header colors */
//   pageTheme: {
//     home: genPageTheme({
//       colors: [color.purpleTelus, color.purpleTelus],
//       shape: shapes.blank,
//     }),
//     documentation: genPageTheme({
//       colors: [color.purpleTelus, color.purpleTelus],
//       shape: shapes.blank,
//     }),
//     tool: genPageTheme({
//       colors: [color.purpleTelus, color.purpleTelus],
//       shape: shapes.blank,
//     }),
//     service: genPageTheme({
//       colors: [color.purpleTelus, color.purpleTelus],
//       shape: shapes.blank,
//     }),
//     website: genPageTheme({
//       colors: [color.purpleTelus, color.purpleTelus],
//       shape: shapes.blank,
//     }),
//     library: genPageTheme({
//       colors: [color.purpleTelus, color.purpleTelus],
//       shape: shapes.blank,
//     }),
//     other: genPageTheme({
//       colors: [color.purpleTelus, color.purpleTelus],
//       shape: shapes.blank,
//     }),
//     app: genPageTheme({
//       colors: [color.purpleTelus, color.purpleTelus],
//       shape: shapes.blank,
//     }),
//     apis: genPageTheme({
//       colors: [color.purpleTelus, color.purpleTelus],
//       shape: shapes.blank,
//     }),
//   },
// });

const customTheme = createTheme({
  palette: {
    ...lightTheme.palette,
    primary: {
      main: color.greenAccessible,
      light: color.greenAccessible,
    },
    secondary: {
      main: color.purpleTelus,
      light: color.purpleDeluge,
    },
    error: {
      main: color.redDark,
      light: color.redLight,
    },
    warning: {
      main: color.amberDark,
    },
    info: {
      main: color.greyMystic,
      dark: color.greyThunder,
      light: color.greyCharcoal,
    },
    success: {
      main: color.greenSanFelix,
    },
    background: {
      default: color.white,
      paper: color.greyAlabaster,
    },
    banner: {
      info: color.purpleTelus,
      error: color.redDark,
      text: color.greyThunder,
      link: color.greyShuttle,
    },
    errorBackground: color.redDark,
    warningBackground: color.amberDark,
    infoBackground: color.greyMystic,
    navigation: {
      background: color.greyAlabaster,
      indicator: color.white,
      color: color.greyThunder,
      selectedColor: color.greenAccessible,
      navItem: {
        hoverBackground: color.greyCloud,
      },
    },
  },
  defaultPageTheme: 'home',
  fontFamily: 'Helvetica Now, Helvetica',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({
      colors: [color.purpleTelus, color.purpleTelus],
      shape: shapes.blank,
    }),
    documentation: genPageTheme({
      colors: [color.purpleTelus, color.purpleTelus],
      shape: shapes.blank,
    }),
    tool: genPageTheme({
      colors: [color.purpleTelus, color.purpleTelus],
      shape: shapes.blank,
    }),
    service: genPageTheme({
      colors: [color.purpleTelus, color.purpleTelus],
      shape: shapes.blank,
    }),
    website: genPageTheme({
      colors: [color.purpleTelus, color.purpleTelus],
      shape: shapes.blank,
    }),
    library: genPageTheme({
      colors: [color.purpleTelus, color.purpleTelus],
      shape: shapes.blank,
    }),
    other: genPageTheme({
      colors: [color.purpleTelus, color.purpleTelus],
      shape: shapes.blank,
    }),
    app: genPageTheme({
      colors: [color.purpleTelus, color.purpleTelus],
      shape: shapes.blank,
    }),
    apis: genPageTheme({
      colors: [color.purpleTelus, color.purpleTelus],
      shape: shapes.blank,
    }),
  },
});

export const telusTheme: BackstageTheme = {
  ...customTheme,
  overrides: {
    ...lightTheme.overrides,
    ...createCustomOverrides(),
  },
};

// export const telusDarkTheme: BackstageTheme = {
//   ...customDarkTheme,
//   overrides: {
//     ...darkTheme.overrides,
//     ...createCustomOverridesDark(),
//   },
// };
