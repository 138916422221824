import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { mfePageRouteRef, rootRouteRef } from './routes';

export const waskPlugin = createPlugin({
  id: 'wask',
  routes: {
    root: rootRouteRef,
    mfePage: mfePageRouteRef
  },
});

export const WaskPage = waskPlugin.provide(
  createRoutableExtension({
    name: 'WaskPage',
    component: () =>
      import('./components/AppCreationPage').then(m => m.WaskCreationPage),
    mountPoint: rootRouteRef,
  }),
);

export const MfeLitePage = waskPlugin.provide(
  createRoutableExtension({
    name: 'MfeLitePage',
    component: () =>
      import('./components/AppCreationPage').then(m => m.MfeLiteCreationPage),
    mountPoint: mfePageRouteRef,
  }),
);
