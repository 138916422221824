/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { PropsWithChildren } from 'react';
// import { FeatureFlagged } from '@backstage/core-app-api';
import { Sidebar } from '@backstage/core-components';
import { useLocation } from 'react-router-dom';
import { NavBar } from './Nav/NavBar';

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const navbarPaths = ['/uds', '/universal-design-system'];
  const location = useLocation();
  // Check if the current path matches any of the navbar paths
  const shouldHideNav = navbarPaths.includes(location.pathname);
  return (
    <div
      className="daniel2"
      style={{
        height: '100%',
        display: 'flex',
        flexFlow: 'column',
      }}
    >
      {!shouldHideNav && <NavBar />}
      <Sidebar />
      {children}
    </div>
  );
};
