import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { DiscoveryApi, IdentityApi } from "@backstage/core-plugin-api";

export type deps = {
  defaultPlugin: string;
  discoveryApi: DiscoveryApi;
  identityApi: IdentityApi;
};

const STATUS_CODES = {
  FORBIDDEN: 403,
};

export abstract class FrontendClient {
  protected readonly axiosClient: AxiosInstance;
  protected readonly defaultPlugin: string;
  protected readonly discoveryApi: DiscoveryApi;
  protected readonly identityApi: IdentityApi;

  constructor({ defaultPlugin, discoveryApi, identityApi }: deps) {
    this.discoveryApi = discoveryApi;
    this.identityApi = identityApi;
    this.defaultPlugin = defaultPlugin;
    this.axiosClient = axios.create();

    this.axiosClient.interceptors.request.use(async (config) => {
      try {
        const { token } = await this.identityApi.getCredentials();
        if (token) config.headers.authorization = `Bearer ${token}`;
        // eslint-disable-next-line no-empty
      } catch {}
      return config;
    });

    this.axiosClient.interceptors.response.use(
      (response) => response,
      async (failedResponse) => {
        let message;
        if (failedResponse.response) {
          const payload = await failedResponse.response.text;
          message = `Request failed with ${failedResponse.response.status} ${failedResponse.response.statusText}, ${payload}`;
          if (failedResponse?.response.status === STATUS_CODES.FORBIDDEN) {
            this.identityApi.signOut();
          }
        } else message = failedResponse;
        throw new Error(message);
      }
    );
  }

  protected async getBaseUrl(path: string, plugin?: string) {
    return `${await this.discoveryApi.getBaseUrl(
      plugin || this.defaultPlugin
    )}${path}`;
  }

  protected async getRequired<T>(
    path: string,
    plugin?: string,
    config?: AxiosRequestConfig
  ): Promise<T> {
    const url = await this.getBaseUrl(path, plugin);
    const response = await this.axiosClient.get(url, config);

    return response.data as T;
  }

  protected async postRequired<T>(
    path: string,
    data: unknown,
    plugin?: string,
    config?: AxiosRequestConfig
  ): Promise<T> {
    const url = await this.getBaseUrl(path, plugin);
    const response = await this.axiosClient.post(url, data, config);
    if (response.status === 500) throw new Error();
    return response.data as T;
  }

  protected async putRequired<T>(
    path: string,
    data: unknown,
    plugin?: string
  ): Promise<T> {
    const url = await this.getBaseUrl(path, plugin);
    const response = await this.axiosClient.put(url, data);

    return response.data as T;
  }

  protected async deleteRequired<T>(path: string, plugin?: string): Promise<T> {
    const url = await this.getBaseUrl(path, plugin);
    const response = await this.axiosClient.delete(url);

    return response.data as T;
  }
}
