import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const aacTemplateLandingPlugin = createPlugin({
  id: 'aac-template-landing',
  routes: {
    root: rootRouteRef,
  },
});

export const AacTemplateLandingPage = aacTemplateLandingPlugin.provide(
  createRoutableExtension({
    name: 'AacTemplateLandingPage',
    component: () =>
      import('./components/LandingPage').then(m => m.LandingPage),
    mountPoint: rootRouteRef,
  }),
);
