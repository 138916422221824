import React, { useContext, useEffect } from 'react';
import {
  StatusError,
  StatusOK,
  Table,
  TableColumn,
} from '@backstage/core-components';
import { differenceInMinutes, parseISO } from 'date-fns';

import {
  useApi,
  configApiRef,
  storageApiRef,
  alertApiRef,
} from '@backstage/core-plugin-api';
import { Link, Typography } from '@material-ui/core';
import { ServiceStatus } from '@telus/plugin-external-health-check-backend';
import { LangContext } from '@telus/frontend-common';
import { t } from '../../translations';
import { externalHealthCheckApiRef } from '../../libs/client';

export const ExternalHealthCheckTable = () => {
  const storage = useApi(storageApiRef);
  const config = useApi(configApiRef);
  const alertApi = useApi(alertApiRef);
  const externalHealthCheckApi = useApi(externalHealthCheckApiRef);
  const { lang } = useContext(LangContext);

  storage.forBucket('ExternalHealthCheck');

  const [statuses, setStatuses] = React.useState<ServiceStatus[]>([]);

  useEffect(() => {
    async function getStatuses() {
      const data = await externalHealthCheckApi.updateAll();
      // create a string that will sort by incidents (descending) and then name (alpabetical)
      if (data.error) {
        setStatuses([]);
        return;
      }
      const sortString = (item: ServiceStatus) =>
        `${0 - item.numberOfIncidents}${item.name}`;

      const sortedStatuses = [...data].sort((a, b) => {
        return sortString(a) < sortString(b) ? -1 : 1;
      });

      setStatuses(await sortedStatuses);
      storage.set('statuses', await sortedStatuses);
      storage.set('updatedAt', new Date().toString());
    }

    const updatedAt = storage.snapshot('updatedAt').value as string;
    const minutesSinceUpdate = differenceInMinutes(
      new Date(),
      parseISO(updatedAt),
    );
    const shouldUpdate = !(minutesSinceUpdate < 5);

    if (shouldUpdate) {
      getStatuses();
    } else {
      setStatuses(
        storage.snapshot('statuses').value as unknown as ServiceStatus[],
      );
    }
  }, [config, storage, alertApi, externalHealthCheckApi]);

  return <DenseTable statuses={statuses} lang={lang} />;
};

type DenseTableProps = {
  statuses: ServiceStatus[];
  lang: 'en' | 'fr';
};

function DenseTable({ statuses, lang }: DenseTableProps) {
  const columns: TableColumn<ServiceStatus>[] = [
    {
      title: t.service[lang],
      render: (row: Partial<ServiceStatus>) => (
        <Typography variant="body2">
          {row.numberOfIncidents ? <StatusError /> : <StatusOK />}
          {row.name}
        </Typography>
      ),
    },
    { title: t.status[lang], field: 'status' },
    {
      title: t.message[lang],
      render: (row: Partial<ServiceStatus>) => (
        <Typography variant="body2">
          <Link color="inherit" href={row.url}>
            {row.message}
          </Link>
        </Typography>
      ),
    },
    {
      title: t.updatedAt[lang],
      render: (row: Partial<ServiceStatus>) => (
        <Typography variant="body2">
          <Link color="inherit" href={row.url}>
            {row.updatedAt ? localizedDate(row.updatedAt, lang) : 'Unavailable'}
          </Link>
        </Typography>
      ),
    },
  ];

  return (
    <Table
      title={t.serviceStatus[lang]}
      subtitle={t.serviceStatusMessage[lang]}
      options={{ search: false, paging: false }}
      columns={columns}
      data={statuses}
      onRowClick={(
        _event,
        rowData: ServiceStatus = {
          message: 'a message',
          numberOfIncidents: 0,
          name: 'a name',
          status: 'a status',
          updatedAt: 'a date',
          url: 'a url',
        },
      ) => {
        window.location.href = rowData.url || '';
      }}
    />
  );
}

function localizedDate(date: string, lang: 'en' | 'fr') {
  const newDate = new Date(date);
  const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
    timeZoneName: 'short',
    timeZone,
  };
  const formattedDate = new Intl.DateTimeFormat(`${lang}-CA`, options).format(
    newDate,
  );

  return formattedDate;
}
