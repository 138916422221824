import { createApiFactory, createPlugin, createRoutableExtension, fetchApiRef, googleAuthApiRef } from '@backstage/core-plugin-api';
import { Entity } from '@backstage/catalog-model';

import { CLOUD_DEPLOY_LOCATION_ANNOTATION, CLOUD_DEPLOY_PIPELINE_ANNOTATION, CLOUD_DEPLOY_PROJECT_ID_ANNOTATION } from './constants';
import { cloudDeployApiRef, CloudDeployClient } from './api';
import { rootRouteRef } from './routes';

export const googleCloudDeployPlugin = createPlugin({
  id: 'google-cloud-deploy',
  apis: [
    createApiFactory({
      api: cloudDeployApiRef,
      deps: {
        fetchApi: fetchApiRef,
        googleAuthApi: googleAuthApiRef
      },
      factory: ({ fetchApi, googleAuthApi }) => 
        new CloudDeployClient({ fetchApi, googleAuthApi })
      ,
    }),
  ],
});

/**
 * Checks if the entity has all required Google Cloud Deploy annotations.
 * @public
 * @param entity {Entity} - The entity to check for the Google Cloud Deploy annotations.
 */
export const isGoogleCloudDeployAvailable = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.[CLOUD_DEPLOY_LOCATION_ANNOTATION]) && 
  Boolean(entity.metadata.annotations?.[CLOUD_DEPLOY_PIPELINE_ANNOTATION]) && 
  Boolean(entity.metadata.annotations?.[CLOUD_DEPLOY_PROJECT_ID_ANNOTATION]);

export const GoogleCloudDeployPage = googleCloudDeployPlugin.provide(
  createRoutableExtension({
    name: 'GoogleCloudDeployPage',
    component: () =>
      import('./components/GoogleCloudDeployPage').then(m => m.GoogleCloudDeployPage),
    mountPoint: rootRouteRef,
  }),
);
