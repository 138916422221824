export const CLOUD_CONSOLE_BASE_URL = 'https://console.cloud.google.com';
export const CLOUD_DEPLOY_PROJECT_ID_ANNOTATION =
  'clouddeploy.googleapis.com/project-id';
export const CLOUD_DEPLOY_LOCATION_ANNOTATION =
  'clouddeploy.googleapis.com/location';
export const CLOUD_DEPLOY_PIPELINE_ANNOTATION =
  'clouddeploy.googleapis.com/delivery-pipeline';
export const CLOUD_DEPLOY_PIPELINE_CONSOLE_BASE_URL =
  `${CLOUD_CONSOLE_BASE_URL}/deploy/delivery-pipelines`;
export const GKE_CLUSTER_DETAILS_CONSOLE_BASE_URL = `${CLOUD_CONSOLE_BASE_URL}/kubernetes/clusters/details`;
