import { FetchApi, OAuthApi } from '@backstage/core-plugin-api'

import { CloudDeployApi, DeliveryPipeline, GetDeliveryPipelineRequest, GetTargetRequest, GetTargetsRequest, Target } from './CloudDeployApi';

export class CloudDeployClient implements CloudDeployApi {
  readonly fetchApi: FetchApi;
  readonly googleAuthApi: OAuthApi;

  constructor({
    fetchApi,
    googleAuthApi,
  }: {
    fetchApi: FetchApi;
    googleAuthApi: OAuthApi;
  }) {
    this.fetchApi = fetchApi;
    this.googleAuthApi = googleAuthApi;
  }

  async getDeliveryPipeline(
    params: GetDeliveryPipelineRequest,
  ): Promise<DeliveryPipeline> {
    const { projectId, location, deliveryPipeline } = params;

    const response = await this.fetchApi.fetch(
      `https://clouddeploy.googleapis.com/v1/projects/${projectId}/locations/${location}/deliveryPipelines/${deliveryPipeline}`,
      {
        headers: {
          Authorization: `Bearer ${await this.getToken()}`,
        },
      },
    );

    if (!response.ok) {
      throw new Error(
        `Cloud Deploy API call failed: ${response.status}:${response.statusText}`,
      );
    }

    return await response.json();
  }

  async getTarget(params: GetTargetRequest): Promise<Target> {
    const { projectId, location, target } = params;

    const response = await this.fetchApi.fetch(
      `https://clouddeploy.googleapis.com/v1/projects/${projectId}/locations/${location}/targets/${target}`,
      {
        headers: {
          Authorization: `Bearer ${await this.getToken()}`,
        },
      },
    );

    if (!response.ok) {
      throw new Error(
        `Cloud Deploy API call failed: ${response.status}:${response.statusText}`,
      );
    }

    const targetDetails = await response.json();

    return { targetId: target, ...targetDetails };
  }

  async getTargets(params: GetTargetsRequest): Promise<Array<Target>> {
    const { projectId, location, targets } = params;

    // Fetch Target details for every Target
    const promises = targets.map((target) => this.getTarget({ projectId, location, target}));

    return await Promise.all(promises);
  }

  async getToken(): Promise<string> {
    return this.googleAuthApi.getAccessToken(
      'https://www.googleapis.com/auth/cloud-platform',
    );
  }
}
