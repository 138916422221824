import { Box, makeStyles, Button } from '@material-ui/core';
import React from 'react';
import { useModeContext } from './PluginCard';
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import { storageApiRef, useApi } from '@backstage/core-plugin-api';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    margin: '8px',
    padding: '8px',
    textTransform: 'none',
  },
}));

export const SelectPluginCard = () => {
  const classes = useStyles();
  const { setMode } = useModeContext();
  const storageApi = useApi(storageApiRef);
  const selectWidget = (widget: string) => {
    setMode('add');
    const widgetsValue = (storageApi.snapshot('widgets').value as string[]) || [
      '',
    ];
    const newWidgetsValue = [...widgetsValue, widget];
    storageApi.set('widgets', newWidgetsValue);
  };

  return (
    <Box className={classes.root}>
      <Button
        className={classes.button}
        variant="outlined"
        startIcon={<ExploreOutlinedIcon />}
        color="secondary"
        onClick={() => selectWidget('dynatrace')}
      >
        Dynatrace: Problems
      </Button>
      <Button
        className={classes.button}
        variant="outlined"
        startIcon={<ExploreOutlinedIcon />}
        color="secondary"
        onClick={() => selectWidget('health')}
      >
        External Service Status Monitor
      </Button>
      <Button
        className={classes.button}
        variant="outlined"
        startIcon={<ExploreOutlinedIcon />}
        color="secondary"
        onClick={() => selectWidget('embargoes')}
      >
        Embargoes
      </Button>
      {/* <Button
        className={classes.button}
        variant="outlined"
        startIcon={<ExploreOutlinedIcon />}
        color="secondary"
      >
        Example 2
      </Button> */}
    </Box>
  );
};
