import { Box, Typography, makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import gitLemur from "../LemurGit.png";

import { NavSearch } from "../../Root/Nav/NavSearch";
import { t } from "../translations";
import { LangContext } from "@telus/frontend-common";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    margin: "0",
    height: "390px",
    backgroundColor: "#F4F4F7",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: "auto",
    },
  },
  leftRoot: {
    display: "flex",
    flexDirection: "column",
    padding: "133px 66px",
    width: "50%",
    minWidth: "590px",
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      padding: "80px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "50px 50px",
      minWidth: "0px",
      alignContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "20px 20px",
    },
  },
  title: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "32px",
      lineHeight: "40px",
    },
  },
  subtitle: {
    padding: "16px 0",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  lemur: {
    background: `url(${gitLemur})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    width: "647px",
    height: "403px",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  searchBar: {
    position: "absolute",
    zIndex: 1,
    width: "440px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    borderRadius: "40px",
    fontSize: "16px",
    [theme.breakpoints.down("md")]: {
      width: "400px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "16px",
      width: "340px",
    },
  },
  searchSubmitIcon: {
    fontSize: "36px",
    color: theme.palette.grey[700],
    position: "absolute",
    zIndex: 1,
    right: "860px",
    top: "353px",
    borderRadius: "50%",
    cursor: "pointer",
    padding: "4px",
    border: "3px solid transparent",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.background.default,
    },
    "&:focus": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main,
      border: "3px solid",
    },
    [theme.breakpoints.down("md")]: {
      top: "302px",
      left: "370px",
    },
    [theme.breakpoints.down("sm")]: {
      top: "252px",
      left: "370px",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

export const WelcomeHeader = () => {
  const classes = useStyles();
  const { lang } = useContext(LangContext);

  return (
    <Box className={classes.root}>
      <Box className={classes.leftRoot}>
        <Typography className={classes.title} variant="h1" color="secondary">
          {t.welcome[lang]}
        </Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>
          {t.description[lang]}
        </Typography>
        <div style={{ display: "flex" }}>
          <NavSearch className={classes.searchBar} onClear={() => {}} />
        </div>
      </Box>
      <Box className={classes.lemur}> </Box>
    </Box>
  );
};
