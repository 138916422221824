import { FrontendClient } from '@telus/frontend-common';
import {
  ConfigApi,
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { ApiRef, createApiRef } from '@backstage/core-plugin-api';

/**
 * {@link @backstage/core-plugin-api#ApiRef}
 */

export const iccApiRef: ApiRef<IccApi> = createApiRef<IccApi>({
  id: 'plugin.iccidentity.service',
});

export interface IccApi {
  createWorkforceIdentityTicket: (data: any) => Promise<any>;
  getPartnerCustomerIdentityMeta: () => Promise<any>;
  createPartnerCustomerIdentityTicket: (data: any) => Promise<any>;
  getSreIdentityMeta: () => Promise<any>;
  createSREIdentityTicket: (data: any) => Promise<any>;
  ticketStatus: (data: any) => Promise<any>;
  ticketAddComment: (data: any) => Promise<any>;
  getDocs: () => Promise<any>;
  getDoc: (query: any) => Promise<any>;
}

type deps = {
  discoveryApi: DiscoveryApi;
  identityApi: IdentityApi;
  configApi: ConfigApi;
};

export class IccClient extends FrontendClient implements IccApi {
  protected readonly configApi: ConfigApi;

  constructor(deps: deps) {
    super({
      discoveryApi: deps.discoveryApi,
      identityApi: deps.identityApi,
      defaultPlugin: 'icc',
    });

    this.configApi = deps.configApi;
  }

  async createWorkforceIdentityTicket(data: any) {
    return await this.postRequired(`/workforce-identity/ticket/create`, data);
  }

  async getPartnerCustomerIdentityMeta() {
    return await this.getRequired(`/partner-customer-identity/meta`);
  }

  async createPartnerCustomerIdentityTicket(data: any) {
    return await this.postRequired(
      `/partner-customer-identity/ticket/create`,
      data,
    );
  }

  async getSreIdentityMeta() {
    return await this.getRequired(`/sre-identity/meta`);
  }

  async createSREIdentityTicket(data: any) {
    return await this.postRequired(`/sre-identity/ticket/create`, data);
  }

  async ticketStatus(data: any) {
    return await this.postRequired(`/ticket/status`, data);
  }

  async ticketAddComment(data: any) {
    return await this.postRequired(`/ticket/add-comment`, data);
  }

  async getDocs() {
    return await this.getRequired(`/docs`);
  }

  async getDoc(query: { path: string }) {
    return await this.postRequired(`/docs/query`, query);
  }
}
