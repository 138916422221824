import { FrontendClient } from '@telus/frontend-common';
import {
  ConfigApi,
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { ApiRef, createApiRef } from '@backstage/core-plugin-api';

/**
 * {@link @backstage/core-plugin-api#ApiRef}
 */

export const externalHealthCheckApiRef: ApiRef<ExternalHealthCheckApi> =
  createApiRef<ExternalHealthCheckApi>({
    id: 'plugin.externalhealthcheck.service',
  });

export interface ExternalHealthCheckApi {
  updateAll(): Promise<any>;
}

type deps = {
  discoveryApi: DiscoveryApi;
  configApi: ConfigApi;
  identityApi: IdentityApi;
};

export class ExternalHealthCheckClient extends FrontendClient implements ExternalHealthCheckApi {
  protected readonly configApi: ConfigApi;

  constructor(deps: deps) {
    super({
      discoveryApi: deps.discoveryApi,
      identityApi: deps.identityApi,
      defaultPlugin: 'external-health-check',
    });

    this.configApi = deps.configApi;
  }

  async updateAll() {
    return await this.getRequired(`/update-all`);
  }
}
