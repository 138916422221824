import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const externalHealthCheckPlugin = createPlugin({
  id: 'external-health-check-frontend',
  routes: {
    root: rootRouteRef,
  },
});

export const ExternalHealthCheckPage = externalHealthCheckPlugin.provide(
  createRoutableExtension({
    name: 'ExternalHealthCheckPage',
    component: () =>
      import('./components/ExternalHealthCheckPage').then(
        m => m.ExternalHealthCheckPage,
      ),
    mountPoint: rootRouteRef,
  }),
);
