import {
  Box,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { PluginCard } from './plugin card modes/PluginCard';
import { storageApiRef, useApi } from '@backstage/core-plugin-api';
import { LangContext } from '@telus/frontend-common';
import { t } from '../translations';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '32px 60px 60px 60px',
    backgroundColor: '#F4F4F7',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  title: {
    margin: '16px 0px',
  },
  subtitle: {
    marginBottom: '16px',
  },
  widgetRoot: {
    display: 'flex',
  },
  leftWidgetRoot: {
    display: 'flex',
    minWidth: '50%',
    maxWidth: '50%',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  rightWidgetRoot: {
    display: 'flex',
    minWidth: '50%',
    maxWidth: '50%',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  mobileWidgetRoot: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: '100%',
      maxWidth: '100%',
    },
  },
}));

export const SelectedPlugins = () => {
  const classes = useStyles();
  const storageApi = useApi(storageApiRef);
  const [savedWidgets, setSavedWidgets] = React.useState([''] as string[]);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));
  const { lang } = useContext(LangContext);

  useEffect(() => {
    const widgetsValue = (storageApi.snapshot('widgets').value as string[]) || [
      '',
    ];
    const newWidgetsValue = [...widgetsValue];
    setSavedWidgets(newWidgetsValue);
  }, [storageApi]);

  storageApi.observe$('widgets').subscribe(() => {
    const widgetsValue = (storageApi.snapshot('widgets').value as string[]) || [
      '',
    ];
    setSavedWidgets([...widgetsValue]);
  });

  const sortWidgets = (side: string) => {
    const selectedWidgets = savedWidgets.map((widget, index) => (
      <PluginCard
        widget={widget}
        position={index}
        mode={widget === '' ? 'add' : 'view'}
        key={index}
      />
    ));
    const sortedWidgets = selectedWidgets.sort((a, b) => {
      if (a.props.widget === '') {
        return 1; // move 'a' to the end of the array
      } else if (b.props.widget === '') {
        return -1; // move 'b' to the end of the array
      }
      return 0; // do not change the order of 'a' and 'b'
    });

    if (side === 'left') {
      return sortedWidgets.filter((_, index) => index % 2 === 0);
    }
    if (side === 'right') {
      return sortedWidgets.filter((_, index) => index % 2 !== 0);
    }
    return sortedWidgets;
  };

  return (
    <Box className={classes.root}>
      <Typography
        variant="h3"
        color="secondary"
        className={classes.title}
      >
        {t.createYourOwnExperience[lang]}
      </Typography>
      <Typography
        variant="body1"
        color="textPrimary"
        className={classes.subtitle}
      >
        {t.selectAWidget[lang]}
      </Typography>
      <Box className={classes.widgetRoot}>
        <Box component="div" className={classes.leftWidgetRoot}>
          {sortWidgets('left')}
        </Box>
        <Box component="div" className={classes.rightWidgetRoot}>
          {sortWidgets('right')}
        </Box>
        <Box component="div" className={classes.mobileWidgetRoot}>
          {mobileView && sortWidgets('mobile')}
        </Box>
      </Box>
    </Box>
  );
};
