import React, { useContext } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import { Button, IconButton, makeStyles } from '@material-ui/core';
import { useMobileContext } from './MobileNavBar';
import { MobileNavLink } from './MobileNavLink';
import { MobileNavTab } from './MobileNavTab';
import { getAdCards, getNavLinks } from '../navHelpers';
import { NavSearch } from '../NavSearch';
import { NavTabSrcContext } from '../types';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
    width: "100%",
    padding: "16px",
    position: "absolute",
    borderTop: "1px solid",
    borderColor: theme.palette.grey[400],
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    zIndex: 10,
  },
  searchBar: {
    display: "flex",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: "8px 16px",
    borderRadius: "40px",
    fontSize: "16px",
    height: "48px",
  },
  searchSubmitIcon: {
    fontSize: "36px",
    color: "grey",
    position: "absolute",
    right: "80px",
    top: "26px",
    borderRadius: "50%",
    cursor: "pointer",
    padding: "4px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.background.default,
    },
    "&:focus": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main,
      border: "3px solid",
    },
  },
  backButton: {
    textTransform: "initial",
    padding: "20px 16px",
    fontWeight: 700,
    fontSize: "16px",
    "&:hover": {
      textDecoration: "underline",
      backgroundColor: "initial",
    },
  },
}));

interface MobileNavDropdownProps {
  type: string;
}

export const MobileNavDropdown = (props: MobileNavDropdownProps) => {
  const classes = useStyles();
  const { setDropdown } = useMobileContext();
  const navTabSrc = useContext(NavTabSrcContext);

  const submitEnter = new KeyboardEvent('keydown', {
    key: 'Enter',
    keyCode: 13,
    bubbles: true,
  });

  const submitSearch = () => {
    const input = document.getElementById("mob-nav-search");
    input?.dispatchEvent(submitEnter);
    setDropdown(false);
  };

  return (
    <div className={classes.root}>
      <NavSearch id="mob-nav-search" onClear={() => { }} />
      <IconButton
        aria-label="Search"
        className={classes.searchSubmitIcon}
        onClick={submitSearch}
        id="search-submit-button"
      >
        <SearchIcon />
      </IconButton>

      {props.type === "Main" && (
        <div>
          <MobileNavTab index={1} title={navTabSrc.Catalog.label} />
          <MobileNavTab index={2} title={navTabSrc.Applications.label} />
          <MobileNavLink
            link={navTabSrc.Resources.href}
            title={navTabSrc.Resources.label}
          />
          <MobileNavLink
            link={navTabSrc.Settings.href}
            title={navTabSrc.Settings.label}
          />
        </div>
      )}
      {props.type === "Catalog" && (
        <div>
          <Button
            className={classes.backButton}
            variant="text"
            startIcon={<ArrowBackIcon />}
            onClick={() => setDropdown(0)}
          >
            Back
          </Button>
          {getNavLinks('Catalog', navTabSrc, true)}
          {getAdCards('Catalog', navTabSrc, true)}
        </div>
      )}
      {props.type === "Applications" && (
        <div>
          <Button
            className={classes.backButton}
            variant="text"
            startIcon={<ArrowBackIcon />}
            onClick={() => setDropdown(0)}
          >
            Back
          </Button>
          {getNavLinks('Applications', navTabSrc, true)}
          {getAdCards('Applications', navTabSrc, true)}
        </div>
      )}
    </div>
  );
};
