import {
    RadarQuadrant,
    RadarRing
} from '@backstage/plugin-tech-radar';

export const rings = new Array<RadarRing>();
rings.push({ id: 'GoldenPath', name: 'GOLDEN PATH', color: '#d1a617' });
rings.push({ id: 'Use', name: 'USE', color: '#93c47d' });
rings.push({ id: 'Trial', name: 'TRIAL', color: '#93d2c2' });
rings.push({ id: 'Assess', name: 'ASSESS', color: '#fbdb84' });
rings.push({ id: 'Hold', name: 'HOLD', color: '#efafa9' });

export const quadrants = new Array<RadarQuadrant>();
quadrants.push({ id: 'Platform', name: 'Platform' });
quadrants.push({ id: 'Technique', name: 'Technique' });
quadrants.push({ id: 'Language/Frameworks', name: 'Language/Frameworks' });
quadrants.push({ id: 'Tool', name: 'Tool' });