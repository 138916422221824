import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';


import { rootRouteRef } from './routes';

export const errorMessagePlugin = createPlugin({
  id: 'error-page',
  routes: {
    root: rootRouteRef,
  },
});

export const ErrorMessagePage = errorMessagePlugin.provide(
  createRoutableExtension({
    name: 'ErrorMessagePage',
    component: () =>
      import('./components/ErrorMessage').then(m => m.ErrorMessage),
    mountPoint: rootRouteRef,
  }),
);

