import {
  RadarEntry,
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage/plugin-tech-radar';
import { FrontendClient } from '@telus/frontend-common';
import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import { rings, quadrants } from './constants';

export class TechRadarClient extends FrontendClient implements TechRadarApi {
    constructor(options: { discoveryApi: DiscoveryApi; identityApi: IdentityApi }) {
      super({
        ...options,
        defaultPlugin: 'tech-radar-backend',
      })
    }

    async load(): Promise<TechRadarLoaderResponse> {
      const entries: RadarEntry[] = await super.getRequired('/issues');

      const blips: TechRadarLoaderResponse = {
        entries,
        rings,
        quadrants,
      };
      return blips;
    }
}
