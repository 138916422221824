import { Box, Typography, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useDesktopContext } from './NavBar';
import { getNavLinks, navigateLink } from './navHelpers';
import { NavTabSrcContext } from './types';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '33%',
    paddingLeft: '10%',
  },
  leftCardLink: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 10px 10px 0',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'initial',
    },
  },
}));

interface LeftCardProps {
  title?: string;
  type: string;
}

interface LeftCardLinkProps {
  title?: string;
  link: string;
}
export const LeftCardLink = (props: LeftCardLinkProps) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { setValue } = useDesktopContext();


  return (
    <Typography
      component="a"
      href={props.link}
      onClick={navigateLink(setValue, navigate)}
      className={classes.leftCardLink}
    >
      {props.title} <ArrowForwardIcon />
    </Typography>
  );
};

export const LeftCard = (props: LeftCardProps) => {
  const navTabSrc = useContext(NavTabSrcContext);
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography
        variant="h3"
        style={{ padding: '32px 0 16px' }}
      >
        {props.title}
      </Typography>
      {getNavLinks(props.type, navTabSrc)}
    </Box>
  );
};
