import React from 'react';
import { Table, TableColumn, Progress } from '@backstage/core-components';
import Alert from '@material-ui/lab/Alert';
import useAsync from 'react-use/lib/useAsync';
import { useApi } from '@backstage/core-plugin-api';
import { Tooltip, Typography } from '@material-ui/core';
import { riskEngineApiRef } from '@telus/plugin-risk-engine-assessments';

type Embargo = {
  message: string;
  startDate: Date;
  endDate: Date;
  risk: number;
  weight: number;
  status: 'Active' | 'Upcoming' | 'Past';
};

type DenseTableProps = {
  embargoes: Embargo[];
  title: string;
};

type StatusColor = 'error' | 'primary' | 'initial';

const statusColor = {
  Active: 'error',
  Upcoming: 'primary',
  Past: 'initial',
};

const DenseTable = ({ title, embargoes }: DenseTableProps) => {
  const columns: TableColumn[] = [
    {
      title: 'Embargo',
      render: (row: Partial<Embargo>) => {
        return (
          <Tooltip title={row.message || ''}>
            <Typography style={{ width: '200px' }} variant="body2" noWrap>
              {row.message}
            </Typography>
          </Tooltip>
        );
      },
      sorting: false,
    },
    {
      title: 'Start',
      render: (row: Partial<Embargo>) => {
        const dateTime = row.startDate?.toString() || 'N/A, N/A';
        const [date, time] = dateTime.split(', ');
        return (
          <Typography style={{ width: '75px' }} variant="body2">
            {date} <br />
            {time}
          </Typography>
        );
      },
      sorting: false,
    },
    {
      title: 'End',
      render: (row: Partial<Embargo>) => {
        const dateTime = row.endDate?.toString() || 'N/A, N/A';
        const [date, time] = dateTime.split(', ');
        return (
          <Typography style={{ width: '75px' }} variant="body2">
            {date} <br />
            {time}
          </Typography>
        );
      },
      sorting: false,
    },
    {
      title: 'Status',
      render: (row: Partial<Embargo>) => {
        const color = row.status ? statusColor[row.status] : 'initial';
        return (
          <Typography
            style={{ width: '75px' }}
            variant="body2"
            color={color as StatusColor}
          >
            {row.status}
          </Typography>
        );
      },
      width: '75',
      sorting: false,
    },
  ];

  return (
    <Table
      title={title}
      options={{ search: false, paging: false }}
      columns={columns}
      data={embargoes}

    />
  );
};

export const Embargoes = () => {
  const riskEngineApi = useApi(riskEngineApiRef);

  const {
    value: embargoes,
    loading,
    error,
  } = useAsync(async (): Promise<Embargo[]> => {
    const { active, upcoming, past } = await riskEngineApi.getEmbargoes();

    return [
      ...active.map((embargo: Embargo) => ({
        ...formatEmbargo(embargo),
        status: 'Active',
      })),
      ...upcoming.map((embargo: Embargo) => ({
        ...formatEmbargo(embargo),
        status: 'Upcoming',
      })),
      ...previous30DaysEmbargoes(past).map((embargo: Embargo) => ({
        ...formatEmbargo(embargo),
        status: 'Past',
      })),
    ];
  }, []);

  if (loading) {
    return <Progress />;
  } else if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <>
      {embargoes?.length || embargoes?.length === 0 ? (
        <DenseTable embargoes={embargoes} title="Embargoes" />
      ) : null}
    </>
  );
};

function localizedDate(date: Date) {
  const newDate = new Date(date);
  const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
    timeZone,
  };
  const formattedDate = new Intl.DateTimeFormat('en-CA', options).format(
    newDate,
  );

  return formattedDate;
}

function formatEmbargo(embargo: Embargo) {
  return {
    ...embargo,
    startDate: localizedDate(embargo.startDate),
    endDate: localizedDate(embargo.endDate),
  };
}

function previous30DaysEmbargoes(embargoes: Embargo[]) {
  const now = new Date();
  const daysInMillieseconds = (days: number) => days * 1000 * 60 * 60 * 24;
  const thirtyDaysInMillieseconds = daysInMillieseconds(30);

  return embargoes.filter(embargo => {
    const endDate = new Date(embargo.endDate);
    const diff = now.getTime() - endDate.getTime();
    return diff < thirtyDaysInMillieseconds;
  });
}
