import {
  createPlugin,
  createRoutableExtension,
} from "@backstage/core-plugin-api";

import { rootRouteRef } from "./routes";

export const gptPlugin = createPlugin({
  id: "gpt-frontend",
  routes: {
    root: rootRouteRef,
  },
});

export const GptPage = gptPlugin.provide(
  createRoutableExtension({
    name: "GptPage",
    component: () => import("./components/GptPage").then((m) => m.GptPage),
    mountPoint: rootRouteRef,
  })
);

export const GptContainerPage = gptPlugin.provide(
  createRoutableExtension({
    name: "ContainerPage",
    component: () =>
      import("./components/GptPage/ContainerPage").then((m) => m.ContainerPage),
    mountPoint: rootRouteRef,
  })
);

export const GptImageGenPage = gptPlugin.provide(
  createRoutableExtension({
    name: "ImageGenPage",
    component: () =>
      import("./components/ImageGen").then((m) => m.ImageGenPage),
    mountPoint: rootRouteRef,
  })
);

export const GptLabPage = gptPlugin.provide(
  createRoutableExtension({
    name: "GptLabPage",
    component: () =>
      import("./components/GptLabPage").then((m) => m.GptLabPage),
    mountPoint: rootRouteRef,
  })
);
