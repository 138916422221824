export const t: Record<string, any> = {
    title:{
        en: `Select a Sign in method`,
        fr: `Sélectionnez une méthode de connexion`
    },
    signIn:{
        en: `Sign in`,
        fr: `Se connecter`
    },
    GoogleTitle:{
        en: `TELUS Sign-In`,
        fr: `Connexion à TELUS`
    },
    GithubTitle:{
        en: `TELUS GitHub Account`,
        fr: `Compte TELUS GitHub`
    },
    GoogleDescription:{
        en: `Click here to sign in with your TELUS corporate account`,
        fr: `Cliquez ici pour vous connecter à votre compte d’entreprise TELUS`
    },
    GithubDescription:{
        en: `Click here to sign in with your TELUS GitHub account`,
        fr: `Cliquez ici pour vous connecter à votre compte TELUS GitHub`
    },
}
