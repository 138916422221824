import React, { createContext, useContext } from "react";
import { AppBar, Link, Tab, Tabs, makeStyles } from "@material-ui/core";
import simplifyLogo from "../simplifyLogo.png";
import { TabPanel } from "../TabPanel";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import ClearIcon from "@material-ui/icons/Clear";
import { MobileNavDropdown } from "./MobileNavDropdown";
import { navigateLink } from "../navHelpers";
import { DropdownMenu } from "../DropdownMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    zIndex: 10,
    display: "flex",
    flexDirection: "row",
    height: "80px",
    backgroundColor: theme.palette.background.default,
    top: "0px",
    width: "100%",
    justifyContent: "space-between",
  },
  flexContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  logo: {
    objectFit: "contain",
    height: "65px",
    cursor: "pointer",
    padding: "5px",
    [theme.breakpoints.up("sm")]: {
      height: "80px",
      padding: "5px",
    },
  },
  menu: {
    display: "flex",
    cursor: "pointer",
    borderRadius: "52px",
    color: theme.palette.grey[800],
    '&.Mui-focusVisible': {
      backgroundColor: '#f5f5f5'
    }
  },
  dummyTab: {
    display: "none",
  },
}));

function a11yProps(index: any) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export type MobileContext = {
  dropdown: number | boolean;
  setDropdown: (v: boolean | number) => void;
};

export const MobileDropdownContext = createContext<MobileContext>({
  dropdown: false,
  setDropdown: () => { },
});

export const useMobileContext = () => useContext(MobileDropdownContext);

export const MobileNavBar = () => {
  const classes = useStyles();
  const isGptPage =
    location.pathname === "/gpt" || location.pathname.includes("spoc");
  const [dropdown, setDropdown] = React.useState<boolean | number>(false);
  const navigate = useNavigate();

  // Leaving this here for now because don't know what it was for, but was causing bug when using tab to navigate
  // useEffect(() => {
  //   const handleKeyDown = (event: KeyboardEvent) => {
  //     if (event.key === "Enter") {
  //       document.removeEventListener("keydown", handleKeyDown);
  //       setDropdown(false);
  //     }
  //   };
  //   if (dropdown !== false) {
  //     document.addEventListener("keydown", handleKeyDown);
  //   } else {
  //     // remove event listener
  //     document.removeEventListener("keydown", handleKeyDown);
  //   }
  // }, [dropdown]);

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: number | boolean
  ) => {
    event.preventDefault();
    setDropdown(newValue);
  };

  return (
    <MobileDropdownContext.Provider value={{ dropdown, setDropdown }}>
      <AppBar className={classes.root} position="static" color="inherit">
        <div className={classes.flexContainer}>
          <Link
            component="a"
            href="/"
            onClick={navigateLink(setDropdown, navigate)}
            tabIndex={0}
          >
            <img
              className={classes.logo}
              src={simplifyLogo}
              alt="TELUS | Simplify Hub"
            />
          </Link>
          <Tabs
            value={dropdown}
            TabIndicatorProps={{
              style: {
                display: "none",
              },
            }}
            onChange={handleChange}
            aria-label="Simplify Hub Mobile Navigation Bar"
          >
            {isGptPage && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <DropdownMenu isMobile />
              </div>
            )}

            {dropdown !== false ? (
              <Tab
                className={classes.menu}
                tabIndex={0}
                icon={<ClearIcon style={{ fontSize: 48 }} />}
                aria-label="mobile-menu-exit"
                onClick={() => setDropdown(false)}
                {...a11yProps(0)}
                disableRipple
              />
            ) : (
              <Tab
                className={classes.menu}
                tabIndex={0}
                icon={<MenuIcon style={{ fontSize: 48 }} />}
                aria-label="mobile-menu"
                onClick={() => setDropdown(0)}
                {...a11yProps(0)}
                disableRipple
              />
            )}
          </Tabs>
        </div>
      </AppBar>
      <TabPanel value={dropdown} index={0}>
        <MobileNavDropdown type="Main" />
      </TabPanel>
      <TabPanel value={dropdown} index={1}>
        <MobileNavDropdown type="Catalog" />
      </TabPanel>
      <TabPanel value={dropdown} index={2}>
        <MobileNavDropdown type="Applications" />
      </TabPanel>
    </MobileDropdownContext.Provider>
  );
};
