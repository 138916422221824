import { Box, IconButton, makeStyles } from '@material-ui/core';
import { DynatraceEntitiesWidget } from '@telus/plugin-dynatrace';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

import { ExternalHealthCheckTable } from '@telus/plugin-external-health-check-frontend';
import { Embargoes } from '../../../embargoes/Embargoes';
import { storageApiRef, useApi } from '@backstage/core-plugin-api';

const useStyles = makeStyles(theme => ({
  viewRoot: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    border: '3px solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: '4px',
  },
  close: {
    position: 'absolute',
    zIndex: 1,
    right: 0,
  },
}));

interface ViewPluginCardProps {
  position: number;
  widget: string;
}

export const ViewPluginCard = (props: ViewPluginCardProps) => {
  const classes = useStyles();
  const showDynatrace = props.widget === 'dynatrace';
  const showExternalHealthCheck = props.widget === 'health';
  const showEmbargoes = props.widget === 'embargoes';

  const storageApi = useApi(storageApiRef);
  const onClose = () => {
    const widgetsValue = (storageApi.snapshot('widgets').value as string[]) || [
      '',
    ];
    const copyWidgetsValue = [...widgetsValue];
    copyWidgetsValue.splice(props.position, 1);
    storageApi.set('widgets', copyWidgetsValue);
  };

  return (
    <Box className={classes.viewRoot}>
      <IconButton className={classes.close} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      {showDynatrace && <DynatraceEntitiesWidget />}
      {showExternalHealthCheck && <ExternalHealthCheckTable />}
      {showEmbargoes && <Embargoes />}
    </Box>
  );
};
