import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import { createSearchResultListItemExtension } from '@backstage/plugin-search-react';

import { rootRouteRef } from './routes';
import { DocsSearchResultListItemProps } from './search';

export const docsViewerFrontendPlugin = createPlugin({
  id: 'docs-viewer-frontend',
  routes: {
    root: rootRouteRef,
  },
});



export const DocsViewerIndexPage = docsViewerFrontendPlugin.provide(
  createRoutableExtension({
    name: 'DocsViewerIndexPage',
    component: () => import('./components/IndexPage').then(m => m.IndexPage),
    mountPoint: rootRouteRef,
  }),
);

export const DocsViewerDocumentPage = docsViewerFrontendPlugin.provide(
  createRoutableExtension({
    name: 'DocsViewerDocumentPage',
    component: () =>
      import('./components/DocumentPage').then(m => m.DocumentPage),
    mountPoint: rootRouteRef,
  }),
);

export const DocsViewerEntityDocumentPage = docsViewerFrontendPlugin.provide(
  createRoutableExtension({
    name: 'DocsViewerEntityDocumentPage',
    component: () =>
      import('./components/EntityDocumentPage').then(m => m.EntityDocumentPage),
    mountPoint: rootRouteRef,
  }),
);

export const DocsViewerHtmlPage = docsViewerFrontendPlugin.provide(
  createRoutableExtension({
    name: 'DocsViewerHtmlPage',
    component: () =>
      import('./components/HtmlPage').then(m => m.HtmlPage),
    mountPoint: rootRouteRef,
  }),
);

export const DocsViewerHtmlDocumentPage = docsViewerFrontendPlugin.provide(
  createRoutableExtension({
    name: 'DocsViewerHtmlDocumentPage',
    component: () =>
      import('./components/HtmlDocumentPage').then(m => m.HtmlDocumentPage),
    mountPoint: rootRouteRef,
  }),
);

export const DocsSearchResultListItem: (
  props: DocsSearchResultListItemProps,
) => JSX.Element | null = docsViewerFrontendPlugin.provide(
  createSearchResultListItemExtension({
    name: 'DocsSearchResultListItem',
    component: () =>
      import('./search/DocsSearchResultListItem').then(
        m => m.DocsSearchResultListItem,
      ),
    predicate: result => result.type === 'docs',
  }),
);
