import { ApiRef, createApiRef } from '@backstage/core-plugin-api';
import {
  ConfigApi,
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { FrontendClient } from '@telus/frontend-common';

/**
 * Utility API reference for the {@link MkDocsCreatorApi}.
 *
 * @public
 */
 export const mkdocsCreatorApiRef: ApiRef<MkDocsCreatorApi> =  createApiRef<MkDocsCreatorApi>({
  id: 'plugin.mkdocs-creator.service',
});

export interface MkDocsCreatorApi {
  getRepositoryData: (url: string) => Promise<any>;
  createPR: (repositoryData: any) => Promise<any>;
}

type deps = {
  discoveryApi: DiscoveryApi;
  configApi: ConfigApi;
  identityApi: IdentityApi;
  githubAuthApi: any;
};

export class MkDocsCreatorClient
  extends FrontendClient
  implements MkDocsCreatorApi
{
  protected readonly configApi: ConfigApi;
  protected readonly githubAuthApi: any;

  constructor(deps: deps) {
    super({
      discoveryApi: deps.discoveryApi,
      identityApi: deps.identityApi,
      defaultPlugin: 'mkdocs-creator',
    });

    this.configApi = deps.configApi;
    this.githubAuthApi = deps.githubAuthApi;
    this.axiosClient.interceptors.request.use(async config => {
      const githubToken = await this.githubAuthApi.getAccessToken();
      if (githubToken)
        config.headers['x-github-access-token'] = `${githubToken}`;

      return config;
    });
  }

  async getRepositoryData(url: string) {
    return this.postRequired('/mkdocs', {
      url
    });
  }

  async createPR(repositoryData: any) {
    return this.postRequired('/mkdocs/pr', {
      ...repositoryData,
    });
  }
}