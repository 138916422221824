import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const tdAcademyFrontendPlugin = createPlugin({
  id: 'td-academy-frontend',
  routes: {
    root: rootRouteRef,
  },
});

export const TdAcademyFrontendMainPage = tdAcademyFrontendPlugin.provide(
  createRoutableExtension({
    name: 'TdAcademyFrontendMainPage',
    component: () =>
      import('./components/Main').then(m => m.Main),
    mountPoint: rootRouteRef,
  }),
);

export const TdAcademyFrontendHomePage = tdAcademyFrontendPlugin.provide(
  createRoutableExtension({
    name: 'TdAcademyFrontendHomePage',
    component: () =>
      import('./components/Home').then(m => m.Home),
    mountPoint: rootRouteRef,
  }),
);

export const TdAcademyFrontendPlaylistsPage = tdAcademyFrontendPlugin.provide(
  createRoutableExtension({
    name: 'TdAcademyFrontendPlaylistsPage',
    component: () =>
      import('./components/Playlists').then(m => m.Playlists),
    mountPoint: rootRouteRef,
  }),
);

export const TdAcademyFrontendUploadPage = tdAcademyFrontendPlugin.provide(
  createRoutableExtension({
    name: 'TdAcademyFrontendUploadPage',
    component: () =>
      import('./components/Upload').then(m => m.Upload),
    mountPoint: rootRouteRef,
  }),
);

export const TdAcademyFrontendManagePage = tdAcademyFrontendPlugin.provide(
  createRoutableExtension({
    name: 'TdAcademyFrontendManagePage',
    component: () =>
      import('./components/Manage').then(m => m.Manage),
    mountPoint: rootRouteRef,
  }),
);