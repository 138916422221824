import React, { useCallback, useEffect } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
  Input,
  InputLabel,
  FormGroup,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useEntity } from '@backstage/plugin-catalog-react';
import { ownershipProviderApiRef } from '@telus/frontend-common';
import { useApi } from '@backstage/core-plugin-api';

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

export const EntityGroupDialog = ({ open, onClose }: any) => {
  const classes = useStyles();
  const { entity } = useEntity<any>();
  const [formState, setFormState] = React.useState<any>({});
  const ownershipProviderApi = useApi(ownershipProviderApiRef);

  const loadEntity = useCallback(
    (key: string) => {
      ownershipProviderApi.getTeamEntity(key).then(res => {
        setFormState({
          kind: res?.response?.kind,
          name: res?.response?.spec?.profile?.name,
          email: res?.response?.spec?.profile?.email,
        });
      });
    },
    [ownershipProviderApi, setFormState],
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const updatedEntity = {
      ...entity,
      spec: {
        ...entity.spec,
        profile: {
          ...entity.spec?.profile,
          name: formState.name,
        },
      },
    };
    ownershipProviderApi.updateTeamEntity(
      entity?.spec?.profile?.email,
      updatedEntity,
    );
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    setFormState({
      ...formState,
      [e.target.name]: value,
    });
  };

  useEffect(() => {
    if (entity?.spec?.profile?.email) {
      loadEntity(entity?.spec?.profile?.email);
    }
  }, [entity, loadEntity]);

  return (
    <Box>
      <Dialog onClose={onClose} open={open} maxWidth="xl" fullWidth>
        <DialogTitle>
          <Typography variant="subtitle1">Group</Typography>
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <FormGroup style={{ marginBottom: '1rem' }}>
              <InputLabel>Kind</InputLabel>
              <Input name="kind" readOnly value={formState.kind} />
            </FormGroup>
            <FormGroup style={{ marginBottom: '1rem' }}>
              <InputLabel>Name</InputLabel>
              <Input
                name="name"
                value={formState.name}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup style={{ marginBottom: '1rem' }}>
              <InputLabel>Email</InputLabel>
              <Input
                name="email"
                value={formState.email}
                // onChange={handleChange}
              />
            </FormGroup>
            <Button type="submit" color="primary" variant="contained">
              Update
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
