import { ApiRef, createApiRef } from '@backstage/core-plugin-api'
import {
  ConfigApi,
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api'
import { FrontendClient } from '@telus/frontend-common'

/**
 * Utility API reference for the {@link TDRMApi}.
 *
 * @public
 */
export const tdrmApiRef: ApiRef<TDRMApi> = createApiRef<TDRMApi>({
  id: 'plugin.tdrm.service',
})

interface TDRMApi {
  getIssue: (url: string) => Promise<any>
  getIssues: () => Promise<any>
  createIssue: (issueData: Object) => Promise<any>
}

type deps = {
  discoveryApi: DiscoveryApi
  configApi: ConfigApi
  identityApi: IdentityApi
}

export class TDRMClient extends FrontendClient implements TDRMApi {
  constructor(deps: deps) {
    super({
      discoveryApi: deps.discoveryApi,
      identityApi: deps.identityApi,
      defaultPlugin: 'tdrm',
    })
  }

  async getIssue(issueNumber: string) {
    return this.postRequired('/issue', { issueNumber })
  }

  async getIssues() {
    return this.getRequired('/issues')
  }

  async createIssue(issueData: Object) {
    return this.postRequired('/create', issueData)
  }
}