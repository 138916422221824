import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ClearIcon from '@material-ui/icons/Clear';
import { useDesktopContext } from './NavBar';
import { getAdCards, navigateLink } from './navHelpers';
import { NavTabSrcContext } from './types';

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '0 16px 16px 16px',
    padding: '16px',
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    textTransform: 'initial',
    fontSize: '16px',
    padding: '8px 0 0 0',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'initial',
    },
  },
}));

interface RightCardListProps {
  type: string;
}

export const RightCardList = (props: RightCardListProps) => {
  const { setValue } = useDesktopContext();
  const navTabSrc = useContext(NavTabSrcContext);

  const closeDropdown = () => {
    setValue(false);
  };
  return (
    <Box style={{ width: '33%', display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <ClearIcon
          onClick={closeDropdown}
          style={{
            margin: '16px 16px 0 16px',
            justifySelf: 'flex-end',
            cursor: 'pointer',
          }}
          tabIndex={0}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              closeDropdown();
            }
          }}
        />
      </div>
      {getAdCards(props.type, navTabSrc)}
    </Box>
  );
};

interface RightCardProps {
  title?: string;
  content?: string;
  link?: string;
  linkTitle?: string;
}

export const RightCard = (props: RightCardProps) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { setValue } = useDesktopContext();

  return (
    <Box className={classes.root} borderRadius={8}>
      <Typography
        variant="subtitle1"
        style={{ fontWeight: '700', paddingBottom: '8px' }}
      >
        {props.title}
      </Typography>
      <Typography variant="body1">{props.content}</Typography>
      <Button
        className={classes.button}
        variant="text"
        component="a"
        href={props.link}
        onClick={navigateLink(setValue, navigate)}
      >
        {props.linkTitle} <ArrowForwardIcon fontSize="small" />
      </Button>
    </Box>
  );
};
