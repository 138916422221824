import React, { useState } from 'react';
import { entityRouteParams, useEntity } from '@backstage/plugin-catalog-react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { GitHubIcon, DocsIcon, Link } from '@backstage/core-components';
import { makeStyles } from '@material-ui/core';
import { AboutContent } from '@backstage/plugin-catalog';
import { getEntitySourceLocation } from '@backstage/catalog-model';
import { EntityDialog } from '../EntityEditor';

const useStyles = makeStyles({
  label: {
    textTransform: 'uppercase',
    color: 'gray',
    fontSize: 14,
    fontWeight: 'bold',
  },
  value: {
    paddingBottom: '1rem',
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '0.5rem',
  },
  icon: {
    marginBottom: '0.5rem',
  },
  linkText: {
    fontSize: 14,
    fontWeight: 'bold',
  },
});

export const EntityAboutCard = () => {
  const classes = useStyles();
  const { entity } = useEntity();
  const [editorOpenClose, setEditorOpenClose] = useState(false);
  const routeParams = entityRouteParams(entity);
  const sourceLocation = getEntitySourceLocation(entity);

  return (
    <div>
      <EntityDialog
        open={editorOpenClose}
        onClose={() => {
          setEditorOpenClose(false);
        }}
      />
      <Card>
        <CardHeader
          title="About"
          action={
            <IconButton
              onClick={() => {
                setEditorOpenClose(true);
              }}
            >
              <EditIcon />
            </IconButton>
          }
          subheader={
            <Box display="flex">
              <Link
                to={sourceLocation?.target || '/'}
                underline="hover"
                className={classes.link}
              >
                <GitHubIcon />
                <Typography
                  variant="body1"
                  align="center"
                  className={classes.linkText}
                >
                  VIEW SOURCE
                </Typography>
              </Link>
              <Link
                to={`/docs/${routeParams.name}`}
                underline="hover"
                className={classes.link}
              >
                <DocsIcon />
                <Typography
                  variant="body1"
                  align="center"
                  className={classes.linkText}
                >
                  VIEW TECHDOCS
                </Typography>
              </Link>
            </Box>
          }
        />
        <CardContent>
          <AboutContent entity={entity} />
        </CardContent>
      </Card>
    </div>
  );
};
