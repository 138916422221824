import {
  createApiRef,
  IdentityApi,
  ProfileInfo,
} from '@backstage/core-plugin-api';

export interface ApiToolsServiceApi {
  getRppProject: (search: string) => Promise<RppProject[]>;
  getTmfOpenAPI: () => Promise<TmfOpenApi[]>;
  getPorfolio: () => Promise<Portfolio[]>;
  getApiDesignWorkspace: () => Promise<ApiDesignWorkspace[]>;
  getApiDesignProjects: (workspace: string) => Promise<ApiDesignProject[]>;
  postApiGovTicket: (ticket: ApifdTicket) => Promise<ApiGovTicketResult>;
  getApifdUser: (search: string) => Promise<ApiFdUser[]>;
  getProfileInfo: () => Promise<ProfileInfo>;
}
export interface ApiDesignWorkspace {
  id: string;
  name: string;
  type: string;
  visibility: string;
}
export interface ApiDesignProject {
  id: string;
  description: string;
  name: string;
  summary: string;
  createdBy: string;
  updatedBy: string;
  isPublic: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface RppProject {
  id: string;
  href: string;
  name: string;
  stage: string;
}

export interface TmfOpenApi {
  id: string;
  href: string;
  name: string;
}

export interface Portfolio {
  id: string;
  name: string;
  porfolio: string;
  platform: string;
  architect: string;
}

export interface ApifdTicket {
  summary: string;
  description: string;
  apifdType: string;
  apiName: string;
  apiVersion: string;
  apiDescription: string;
  apiConsumers: string;
  apiDiagramLink: string;
  apiDesignWorkspace: string;
  apiDesignProject: string;
  cmdbId: string;
  apiDomain: string;
  apiLayer: string;
  apiArchitect: string;
  portfolio: string;
  portfolioArchitect: string;
  industryStandardInterface: string;
  tmfDesign: string;
  tmfDesignOther: string;
  projectName: string;
  wbsCode: string;
  prDate: string;
  deploymentStrategy: string;
  gateway: string;
  gatewayOther: string;
  apisListDecommision: string;
  attachments: string;
  watchers: [];
  reporter: string;
  securityTeamEngaged: string;
  securityNumber: string;
}

export interface ApiGovTicketResult {
  id: number;
  self: string;
  key: string;
  href: string;
}

export interface ApiFdUser {
  id: number;
  name: string;
  emailAddress: string;
  displayName: string;
}

export const apiToolsApiRef = createApiRef<ApiToolsServiceApi>({
  id: 'plugin.api-tools.service',
});

export type Options = {
  backendBaseUrl: string;
  identityApi: IdentityApi;
};

export class ApiToolsServiceApiClient implements ApiToolsServiceApi {
  private readonly backendBaseUrl: string;
  private readonly identityApi: IdentityApi;

  constructor(options: Options) {
    this.backendBaseUrl = options.backendBaseUrl;
    this.identityApi = options.identityApi;
  }

  private async getBaseUrl() {
    return `${this.backendBaseUrl}/api/api-tools`;
  }

  async getProfileInfo(): Promise<ProfileInfo> {
    const profileInfo = await this.identityApi.getProfileInfo();
    return profileInfo;
  }

  async getRppProject(search: string): Promise<RppProject[]> {
    if (search.length < 2) {
      return [];
    }
    const { token } = await this.identityApi.getCredentials();
    const proxyUrl = await this.getBaseUrl();
    const url = `${proxyUrl}/rpp?search=${search}`;

    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async response => {
        const resp = await response.json();
        return resp;
      })
      .catch(_ => {
        throw new Error('Cannot get rpp projects.');
      });
  }

  async getTmfOpenAPI(): Promise<TmfOpenApi[]> {
    const { token } = await this.identityApi.getCredentials();
    const proxyUrl = await this.getBaseUrl();
    const url = `${proxyUrl}/tmfOpenApiTable`;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async response => {
        const resp = await response.json();
        return resp;
      })
      .catch(_ => {
        throw new Error('Cannot get Api TMFOpenApi projects.');
      });
  }

  async getPorfolio(): Promise<Portfolio[]> {
    const { token } = await this.identityApi.getCredentials();
    const proxyUrl = await this.getBaseUrl();
    const url = `${proxyUrl}/portfolio`;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async response => {
        const resp = await response.json();
        return resp;
      })
      .catch(_ => {
        throw new Error('Cannot get porfolio list.');
      });
  }

  async getApifdUser(search: string): Promise<ApiFdUser[]> {
    if (search.length < 3) {
      return [];
    }
    const { token } = await this.identityApi.getCredentials();
    const proxyUrl = await this.getBaseUrl();
    const url = `${proxyUrl}/apiGovTicket/user?search=${search}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async response => {
        const resp = await response.json();
        return resp;
      })
      .catch(_ => {
        throw new Error('Cannot get users.');
      });
  }

  async postApiGovTicket(ticket: ApifdTicket): Promise<ApiGovTicketResult> {
    const { token } = await this.identityApi.getCredentials();

    const proxyUrl = await this.getBaseUrl();
    const url = `${proxyUrl}/apiGovTicket`;

    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(ticket),
    })
      .then(async response => {
        if (response.status >= 300) {
          throw new Error(response.statusText);
        }
        const resp = await response.json();
        return resp;
      })
      .catch(_ => {
        throw new Error(`Couldn't post apifd ticket. ${_.message}`);
      });
  }

  async getApiDesignWorkspace(): Promise<ApiDesignWorkspace[]> {
    const { token } = await this.identityApi.getCredentials();

    const proxyUrl = await this.getBaseUrl();
    const url = `${proxyUrl}/apiDesign/workspace`;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async response => {
        const resp = await response.json();
        return resp;
      })
      .catch(_ => {
        throw new Error('Cannot get workspaces.');
      });
  }

  async getApiDesignProjects(workspace: string): Promise<ApiDesignProject[]> {
    const { token } = await this.identityApi.getCredentials();

    const proxyUrl = await this.getBaseUrl();
    const url = `${proxyUrl}/apiDesign/project${
      workspace && workspace !== '' ? `?workspace=${workspace}` : ''
    }`;

    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async response => {
        const resp = await response.json();
        return resp;
      })
      .catch(_ => {
        throw new Error('Cannot get Api design projects.');
      });
  }
}
