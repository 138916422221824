import { FrontendClient } from '@telus/frontend-common';
import {
  ConfigApi,
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { ApiRef, createApiRef } from '@backstage/core-plugin-api';

/**
 * {@link @backstage/core-plugin-api#ApiRef}
 */
export interface TascApi {
  createCloudflareIntakeTicket: (data: any) => Promise<any>;
  ticketStatus: (data: any) => Promise<any>;
  ticketAddComment: (data: any) => Promise<any>;
  getDocs: () => Promise<any>;
  getDoc: (query: any) => Promise<any>;
}

export const tascApiRef: ApiRef<TascApi> = createApiRef<TascApi>({
  id: 'plugin.tasc.service'
});

type Deps = {
  discoveryApi: DiscoveryApi;
  identityApi: IdentityApi;
  configApi: ConfigApi;
};

export class TascApiClient extends FrontendClient implements TascApi {
  protected readonly configApi: ConfigApi;

  constructor(deps: Deps) {
    super({
      discoveryApi: deps.discoveryApi,
      identityApi: deps.identityApi,
      defaultPlugin: 'tasc',
    });

    this.configApi = deps.configApi;
  }

  async createCloudflareIntakeTicket(data: any) {
    return await this.postRequired(`/cloudflare-intake/ticket/create`, data);
  }

// TODO: Build types instead of "any"
  async ticketStatus(data: any) {
    return await this.postRequired(`/ticket/status`, data);
  }

  async ticketAddComment(data: any) {
    return await this.postRequired(`/ticket/add-comment`, data);
  }

  async getDocs() {
    return await this.getRequired(`/docs`);
  }

  async getDoc(query: { path: string }) {
    return await this.postRequired(`/docs/query`, query);
  }
}
