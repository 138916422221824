import { FrontendClient } from "@telus/frontend-common";
import {
  ConfigApi,
  DiscoveryApi,
  IdentityApi,
} from "@backstage/core-plugin-api";
import { ApiRef, createApiRef } from "@backstage/core-plugin-api";
import {
  ImagePromptOptions,
  GeneratedImage,
  imageVisibilities,
} from "../components/ImageGen/types";
import { PromptSuggestion, FileData } from "../components/GptPage/types";

/**
 * {@link @backstage/core-plugin-api#ApiRef}
 */

export const gptApiRef: ApiRef<GPTApi> = createApiRef<GPTApi>({
  id: "plugin.gpt.service",
});

type deps = {
  discoveryApi: DiscoveryApi;
  configApi: ConfigApi;
  identityApi: IdentityApi;
};

type Prompt = {
  messages: Message[];
  lastPrompt: string;
  uuid: string;
  user: string;
};

type ImageGenPayload = {
  prompt: string;
  uuid: string;
  userEmail: string;
  lang: string;
  imagePromptOptions: ImagePromptOptions;
};

export type ImageSetResponse = {
  images: GeneratedImage[];
  numberOfSets: number;
};

export type ImageGenResponse = {
  success: boolean;
  message?: string;
  image?: GeneratedImage;
};

type EditImagePrompt = {
  image: string;
  mask: string;
  lastPrompt: string;
  messages: Message[];
  uuid: string;
  user: string;
};

type Message = {
  role: string;
  content: string;
};

export interface GPTApi {
  request: (prompt: Prompt, search: string) => Promise<any>;
  generateImage: (payload: ImageGenPayload) => Promise<ImageGenResponse>;
  getImageGallery: () => Promise<GeneratedImage[]>;
  getImageSet: (start: number, nb: number) => Promise<GeneratedImage[]>;
  getNumberOfImages: () => Promise<number>;
  getSingleImage: (imageFilename: string) => Promise<GeneratedImage>;
  getImageGenerationHistory: (userEmail: string) => Promise<GeneratedImage[]>;
  setImageVisibility: (
    user: string,
    image: GeneratedImage,
    visibility: (typeof imageVisibilities)[number]
  ) => Promise<any>;
  deleteImage: (
    user: string,
    imageFilename: string
  ) => Promise<ImageGenResponse>;
  searchImages: (query: string) => Promise<GeneratedImage[]>;
  reportImage: (imageFilename: string) => Promise<GeneratedImage>;
  editImage: (prompt: EditImagePrompt) => Promise<any>;
  messageFeedback: (liked: any, prod: boolean) => Promise<any>;
  getChatHistory: (user: string, type: string) => Promise<any>;
  deleteConversation: (conversationId: string, type: string) => Promise<any>;

  initGenesysChatSession: (chatSessionInfo: any) => Promise<any>;
  getGenesysMessages: <T>(
    sessionId: string,
    nexusToken: string,
    conversationID: string
  ) => Promise<T>;
  sendGenesysMessages: <T>(
    sessionId: string,
    nexusToken: string,
    text: string,
    conversationID: string
  ) => Promise<T>;
  sendGenesysImage: <T>(
    sessionId: string,
    nexusToken: string,
    formData: any
  ) => Promise<T>;
  leaveGenesysChatSession: <T>(
    sessionId: string,
    nexusToken: string,
    participant: string,
    conversationID: string
  ) => Promise<T>;

  notifyGenesysTyping: <T>(
    sessionId: string,
    nexusToken: string,
    type: "start" | "stop"
  ) => Promise<T>;

  getCopilots: (
    user: string,
    visbility: string,
    approved: boolean,
    language: string,
    prod: boolean
  ) => Promise<any>;
  getCopilot: (
    id: string,
    language: string,
    prod: boolean,
    googleId: string
  ) => Promise<any>;
  getTools: (prod: boolean) => Promise<any>;
  getAdditionalInputs: (tool: string, prod: boolean) => Promise<any>;
  createCopilot: (
    id: number,
    name: string,
    image: string,
    description: string,
    files: FileData[],
    custom_instruction: string,
    preset_prompt: PromptSuggestion[],
    shared_with: any[],
    visibility: string,
    approved: boolean,
    owner: {
      id: string;
      language: string;
    },
    tools: any,
    allow_file_uploads: boolean,
    prod: boolean
  ) => Promise<any>;
  deleteCopilot: (copilotId: string, prod: boolean) => Promise<any>;
  getCopilotHistory: (user: string, prod: boolean) => Promise<any>;
  deleteCopilotHistory: (conversationId: string, prod: boolean) => Promise<any>;

  uploadFiles: (
    copilotId: string,
    files: FormData,
    userEmail: string,
    prod: boolean
  ) => Promise<any>;
  deleteFiles: (
    copilotId: string,
    files: readonly string[],
    prod: boolean
  ) => Promise<any>;

  getContentfulAnnouncements: (lang: string) => Promise<any>;
  getContentfulInterruptAnnouncement: (lang: string) => Promise<any>;
  getContentfulAnnouncementById: (id: string, lang: string) => Promise<any>;
}

export class GPTClient extends FrontendClient implements GPTApi {
  protected readonly configApi: ConfigApi;

  constructor(deps: deps) {
    super({
      discoveryApi: deps.discoveryApi,
      identityApi: deps.identityApi,
      defaultPlugin: "gpt",
    });

    this.configApi = deps.configApi;
  }

  async request(prompt: Prompt, search: string) {
    return await this.postRequired("/chat", {
      prompt,
      search,
    });
  }

  async getChatHistory(user: string, type: string) {
    return await this.postRequired("/getChatHistory", {
      user,
      type,
    });
  }

  async getCopilots(
    user: string,
    visibility: string,
    approved: boolean,
    language: string,
    prod: boolean
  ) {
    return await this.postRequired("/getCopilots", {
      user,
      visibility,
      approved,
      language,
      prod,
    });
  }

  async getCopilot(
    id: string,
    language: string,
    prod: boolean,
    googleId: string
  ) {
    return await this.postRequired("/getCopilot", {
      id,
      language,
      prod,
      googleId,
      auth_provider: "google",
    });
  }

  async getTools(prod: boolean) {
    return await this.postRequired("/getTools", { prod });
  }

  async getAdditionalInputs(tool: string, prod: boolean) {
    return await this.postRequired("/getAdditionalInputs", {
      tool,
      prod,
    });
  }

  async createCopilot(
    id: number,
    name: string,
    image: string,
    description: string,
    files: any,
    custom_instructions: string,
    preset_prompt: PromptSuggestion[],
    shared_with: any[],
    visibility: string,
    approved: boolean,
    owner: {
      id: string;
    },
    tools: any,
    allow_file_uploads: boolean,
    prod: boolean
  ) {
    return await this.postRequired("/createCopilot", {
      id,
      name,
      image,
      description,
      files,
      preset_prompt,
      custom_instructions,
      shared_with,
      visibility,
      approved,
      owner,
      tools,
      allow_file_uploads,
      prod,
    });
  }

  async getCopilotHistory(user: string, prod: boolean) {
    return await this.postRequired("/getCopilotHistory", {
      user,
      prod,
    });
  }
  async deleteCopilotHistory(conversationId: string, prod: boolean) {
    return await this.postRequired("/deleteCopilotHistory", {
      conversationId,
      prod,
    });
  }
  async deleteCopilot(copilotId: string, prod: boolean) {
    return await this.postRequired("/deleteCopilot", {
      copilotId,
      prod,
    });
  }

  async deleteConversation(conversationId: string, type: string) {
    return await this.postRequired("/delete", {
      conversationId,
      type,
    });
  }

  async messageFeedback(liked: any, prod: boolean) {
    return await this.postRequired("/messageFeedback", {
      liked,
      prod,
    });
  }

  async generateImage(payload: ImageGenPayload) {
    return (await this.postRequired(
      "/generateImage",
      payload
    )) as ImageGenResponse;
  }

  async getImageGallery() {
    return (await this.getRequired("/image-gallery")) as GeneratedImage[];
  }

  async getImageSet(start: number, nb: number) {
    return (await this.postRequired("/image-gallery-set", {
      start,
      nb,
    })) as GeneratedImage[];
  }

  async getNumberOfImages() {
    return (await this.getRequired("/image-gallery-count")) as number;
  }

  async getSingleImage(imageFilename: string) {
    return (await this.getRequired(
      `/image-gallery/${imageFilename}`
    )) as GeneratedImage;
  }

  async getImageGenerationHistory(userEmail: string) {
    return (await this.postRequired("/image-gen-history", {
      userEmail,
    })) as GeneratedImage[];
  }

  async setImageVisibility(
    user: string,
    image: GeneratedImage,
    visibility: (typeof imageVisibilities)[number]
  ) {
    return await this.postRequired("/image-visibility", {
      user,
      image,
      visibility,
    });
  }

  async reportImage(imageFilename: string) {
    return (await this.postRequired("/report-image", {
      imageFilename,
    })) as GeneratedImage;
  }

  async deleteImage(user: string, imageFilename: string) {
    return (await this.postRequired("/delete-image", {
      user,
      imageFilename,
    })) as ImageGenResponse;
  }

  async searchImages(query: string) {
    return (await this.postRequired("/search-images", {
      query,
    })) as GeneratedImage[];
  }

  async editImage(prompt: EditImagePrompt) {
    return await this.postRequired("/editImage", {
      prompt,
    });
  }

  async uploadFiles(
    copilotId: string,
    files: FormData,
    userEmail: string,
    prod: boolean
  ) {
    files.append("data", JSON.stringify({ prod: prod, user: userEmail }));

    return await this.postRequired(`/copilots/${copilotId}/files`, files);
  }

  async deleteFiles(
    copilotId: string,
    files: readonly string[],
    prod: boolean
  ) {
    return await this.postRequired(`/copilots/${copilotId}/files/delete`, {
      files,
      prod,
    });
  }

  async initGenesysChatSession(chatSeesionInfo: string) {
    return await this.postRequired("/initGenesysChatSession", chatSeesionInfo);
  }

  async getGenesysMessages<T>(
    sessionId: string,
    nexusToken: string,
    conversationID: string
  ): Promise<T> {
    return await this.getRequired(
      `/getGenesysChatMessages/${sessionId}?conversationID=${conversationID}`,
      undefined,
      {
        headers: { "x-nexus-token": nexusToken },
      }
    );
  }

  async sendGenesysMessages<T>(
    sessionId: string,
    nexusToken: string,
    text: string,
    conversationID: string
  ): Promise<T> {
    return await this.postRequired(
      `/sendGenesysChatMessage/${sessionId}`,
      { message: text, conversationID },
      undefined,
      {
        headers: { "x-nexus-token": nexusToken },
      }
    );
  }

  async sendGenesysImage(
    sessionId: string,
    nexusToken: string,
    formData: any
  ): Promise<any> {
    return await this.postRequired(
      `/sendGenesysChatImage/${sessionId}`,
      formData,
      undefined,
      {
        headers: { "x-nexus-token": nexusToken },
      }
    );
  }

  async notifyGenesysTyping<T>(
    sessionId: string,
    nexusToken: string,
    type: "start" | "stop"
  ): Promise<T> {
    return await this.postRequired(
      `/notifyGenesysTyping/${sessionId}/${type}`,
      undefined,
      undefined,
      {
        headers: { "x-nexus-token": nexusToken },
      }
    );
  }

  async leaveGenesysChatSession<T>(
    sessionId: string,
    nexusToken: string,
    participant: string,
    conversationID: string
  ): Promise<T> {
    return await this.postRequired(
      `/leaveSession/${sessionId}/${participant}`,
      { conversationID },
      undefined,
      {
        headers: { "x-nexus-token": nexusToken },
      }
    );
  }

  async getContentfulAnnouncements<T>(lang: string): Promise<T> {
    return await this.getRequired(
      `/contentfulAnnouncements?lang=${lang}`,
      undefined,
      {}
    );
  }

  async getContentfulInterruptAnnouncement<T>(lang: string): Promise<T> {
    return await this.getRequired(
      `/contentfulInterruptAnnouncement?lang=${lang}`,
      undefined,
      {}
    );
  }

  async getContentfulAnnouncementById<T>(id: string, lang: string): Promise<T> {
    return await this.getRequired(
      `/contentfulAnnouncementById/${id}?lang=${lang}`,
      undefined,
      {}
    );
  }
}
