/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */


import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MockStarredEntitiesApi } from '@backstage/plugin-catalog-react';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { WelcomeHeader } from './components/WelcomeHeader';
import { HomeNews } from './components/HomeNews';
import { SelectedPlugins } from './components/SelectedPlugins';

const starredEntitiesApi = new MockStarredEntitiesApi();
starredEntitiesApi.toggleStarred('component:default/example-starred-entity');
starredEntitiesApi.toggleStarred('component:default/example-starred-entity-2');
starredEntitiesApi.toggleStarred('component:default/example-starred-entity-3');
starredEntitiesApi.toggleStarred('component:default/example-starred-entity-4');

export const HomePage = () => {

  const navigate = useNavigate();
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    const referrer = document.referrer;

    if (referrer && referrer.includes('developers.telus.com')) {
      setShouldRedirect(true);
    }
  }, []);

  if (shouldRedirect) {
    // Redirect logic here, for example:
    navigate('/docs');
    return null; // Prevents rendering the rest of the component
  }

  return (
    <SearchContextProvider>
          <WelcomeHeader />
          <HomeNews />
          <SelectedPlugins />
    </SearchContextProvider>
  );
};
