export const t: Record<string, any> = {
  welcome: {
    en: 'Welcome to SimplifyHUB',
    fr: 'Bienvenue sur SimplifyHUB'
  },
  description: {
    en: 'Use SimplifyHUB to find everything you didn\'t know you needed.',
    fr: 'Utilisez SimplifyHUB pour trouver tout ce dont vous ne saviez pas que vous aviez besoin.'
  },
  checkOutWhatsNew: {
    en: 'Check out what\'s new',
    fr: 'Découvrez les nouveautés'
  },
  News: {
    en: 'The Simplify Hub team is excited to announce the launch of ',
    fr: 'L\'équipe de Simplify Hub est heureuse d\'annoncer le lancement de '
  },
  vidLibFirstPart: {
    en: 'With Video Library,  users can create and manage the playlists of videos from a Google Drive or YouTube link, and share them with others in the organization. Video Library is a flexible tool with multiple uses:',
    fr: 'Avec la Bibliothèque Vidéo, les utilisateurs peuvent créer et gérer des listes de lecture à partir d\'un lien Google Drive ou YouTube et les partager avec d\'autres personnes de l\'organisation. La Bibliothèque Vidéo est un outil flexible avec de multiples utilisations:'
  },
  imgAlt: {
    en: 'Image Creator Copilot page screenshot',
    fr: 'Capture d\'écran de la page du Créateur d\'images Copilot'
  },
  ImageCreator: {
    en: 'Image Creator Copilot',
    fr: 'Créateur d\'images Copilot'
  },
  VideoLibrary: {
    en: 'Video Library',
    fr: 'Bibliothèque Vidéo'
  },
  imgCreatorFirst: {
    en: 'Image Creator Copilot is a powerful tool that can create stunning images based on prompts. We invite you all to visit our AI Image Generator and experience the magic firsthand.  Don\’t forget to share your creations with the team and spread the word about this incredible tool!',
    fr: 'Le Créateur d\'images Copilot est un outil puissant qui peut créer des images étonnantes en fonction de requête. Nous vous invitons tous à visiter notre générateur d\'images et à vivre la magie par vous-même. N\'oubliez pas de partager vos créations avec l\'équipe et de faire connaître cet outil incroyable!'
  },
  vidLibBulletOne: {
    en: 'Create a playlist for videos in a series, such as tutorials, online training courses, team meetings and more.',
    fr: 'Créez une liste de lecture pour les vidéos d\'une série, comme des tutoriels, des cours de formation en ligne, des réunions d\'équipe et plus encore.'
  },
  vidLibBulletTwo: {
    en: 'Viewers across the organization can access and watch the same playlists, and collaborate by leaving comments.',
    fr: 'Les spectateurs de toute l\'organisation peuvent accéder et regarder les mêmes listes de lecture, et collaborer en laissant des commentaires.'
  },
  feedback: {
    en: 'Feel free to provide your feedback and ideas on our slack channel ',
    fr: 'N\'hésitez pas à nous faire part de vos commentaires et idées sur notre canal Slack '
  },
  createYourOwnExperience: {
    en: 'Create your own SimplifyHUB experience',
    fr: 'Créez votre propre expérience SimplifyHUB'
  },
  selectAWidget: {
    en: 'Select a widget below to customize which applications appear.',
    fr: 'Sélectionnez un widget ci-dessous pour personnaliser quelles applications sont affichées sur votre page d\'accueil.'
  },
  addApp: {
    en: 'Add app',
    fr: 'Ajouter une application'
  },
};
