import { SearchBarBase, useSearch } from '@backstage/plugin-search-react';
import React, { useContext } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { LangContext } from '@telus/frontend-common';
import { t } from './translations';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '0',
    marginBottom: '0',
    '& > *': {
      borderRadius: '40px',
      boxShadow: theme.shadows[1],
      backgroundColor: theme.palette.background.paper,
      height: '50px',
    },
  },
  test: {
  },
  input: {
    height: '10px',
  },
}));
interface NavSearchProps {
  onClear: () => void;
  id?: string;
  className?: string;
}

export const NavSearch = (props: NavSearchProps) => {
  const { term, setTerm } = useSearch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { lang } = useContext(LangContext);

  const mergeClasses = () => {
    if (props.className) {
      return `${classes.root} ${props.className}`;
    }
    return classes.root;

  }
  const updateTerm = (value: string) => {
    setTerm(value);
  };

  const closeSearch = () => {
    props.onClear();
    setTerm('');
  };

  const handleSubmit = () => {
    if (term !== '') {
      closeSearch();
      navigate(`/search?query=${term}`);
      setTerm('');
    }
  };

  return (
    <SearchBarBase
      inputProps={{ className: classes.input }}
      InputProps={{
        startAdornment: false,
        endAdornment: (
          <IconButton
            aria-label="close-search"
            tabIndex={0}
            onClick={closeSearch}
          >
            <CloseIcon />
          </IconButton>
        ),
      }}
      id={props.id}
      className={mergeClasses()}
      onChange={updateTerm}
      onSubmit={handleSubmit}
      onClear={closeSearch}
      value={term}
      placeholder={t.search[lang]}
    />
  );
};
