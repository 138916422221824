import { Box, Grid, Link, ListItem, Typography, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import imgGen from './img-gen.png';
// import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { LangContext } from '@telus/frontend-common';
import { t } from '../translations';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '32px 60px 60px 60px',
    [theme.breakpoints.down('xs')]: {
      padding: '50px',
    },
  },
  title: {
    margin: '16px 0px',
  },
  subtitle: {
    marginBottom: '18px',
  },
  subtitle2: {
    marginBottom: '3px',
  },
  listItem: {
    display: 'list-item',
    fontSize: '16px',
  },
  image: {
    width: '100%',
    height: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'auto',
    },
  }
}));

export const HomeNews = () => {
  const { lang } = useContext(LangContext);
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography className={classes.title} variant="h2" color="secondary">
        {t.checkOutWhatsNew[lang]}
      </Typography>
      <Typography
        className={classes.subtitle}
        variant="body1"
        color="textPrimary"
      >
        {t.News[lang]}
        <RouterLink
          style={{ color: 'green', textDecoration: 'underline' }}
          to="/video-library"
        >
          {t.VideoLibrary[lang]}
        </RouterLink>
        {' & '}
        <RouterLink
          style={{ color: 'green', textDecoration: 'underline' }}
          to="/gpt/image-gen"
        >
          {t.ImageCreator[lang]}
        </RouterLink>
      </Typography>
      <Grid container alignItems='center' >
        <Grid item xs={12} sm={6} md={4} >
          <img src={imgGen} alt={t.imgAlt[lang]} className={classes.image} />
        </Grid>
        <Grid item xs={12} sm={6} md={8} >
          <Typography
            className={classes.subtitle}
            variant="body1"
            color="textPrimary"
          >
            {t.imgCreatorFirst[lang]}
          </Typography>
          <Typography
            className={classes.subtitle2}
            variant="body1"
            color="textPrimary"
          >
            {t.vidLibFirstPart[lang]}
          </Typography>
          <ListItem dense className={classes.listItem} >
            {t.vidLibBulletOne[lang]}
          </ListItem>
          <ListItem dense className={`${classes.listItem} ${classes.subtitle2}`}>
            {t.vidLibBulletTwo[lang]}
          </ListItem>

          <Typography
            className={classes.subtitle}
            variant="body1"
            color="textPrimary"

          >
            {t.feedback[lang]}
            <Link
              href="https://telusdigital.slack.com/archives/C03CN3X3FBJ"
              aria-label='g-simplifyhub'
              style={{ textDecoration: 'underline' }}
            >
              #g-simplifyhub
            </Link>
          </Typography>
        </Grid>
      </Grid>

    </Box>
  );
};
