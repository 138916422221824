import React from 'react';
import { Box, IconButton, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

export const InputLinks = ({ value, onChange }: any) => {
  const [links, setLinks] = React.useState(value || [{ title: '', url: '' }]);

  const handleAdd = (index: number) => {
    const newLinks = [...links];
    newLinks.splice(index + 1, 0, { title: '', url: '' });
    setLinks(newLinks);
  };

  const handleRemove = (index: number) => {
    const newLinks = [...links];
    newLinks.splice(index, 1);
    setLinks(newLinks);
  };

  const handleTitleChange = (e: any, index: number) => {
    const newLinks = [...links];
    newLinks[index].title = e.target.value;
    setLinks(newLinks);
    onChange(
      newLinks.filter((link: any) => link.title !== '' && link.url !== ''),
    );
  };

  const handleUrlChange = (e: any, index: number) => {
    const newLinks = [...links];
    newLinks[index].url = e.target.value;
    setLinks(newLinks);
    onChange(
      newLinks.filter((link: any) => link.title !== '' && link.url !== ''),
    );
  };

  return (
    <React.Fragment>
      {links.map((link: any, index: number) => (
        <Box display="flex">
          <TextField
            value={link.title}
            style={{ width: '40%', marginRight: '10px' }}
            placeholder="Title of Link"
            onChange={e => {
              handleTitleChange(e, index);
            }}
          />
          <TextField
            value={link.url}
            style={{ width: '60%' }}
            placeholder="URL (https://example.com/index.html)"
            onChange={e => {
              handleUrlChange(e, index);
            }}
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  <IconButton
                    onClick={() => {
                      handleAdd(index);
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                  <IconButton
                    disabled={links.length === 1}
                    onClick={() => {
                      handleRemove(index);
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>
                </React.Fragment>
              ),
            }}
          />
        </Box>
      ))}
    </React.Fragment>
  );
};
