import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  oauthRequestApiRef,
  githubAuthApiRef,
  identityApiRef,
  errorApiRef,
  analyticsApiRef,
  storageApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { GithubAuth, MultipleAnalyticsApi } from '@backstage/core-app-api';

import { techRadarApiRef } from '@backstage/plugin-tech-radar';
import { TechRadarClient } from './lib/techRadarClient';
import {
  graphQlBrowseApiRef,
  GraphQLEndpoints,
} from '@backstage/plugin-graphiql';
import { AlloyAnalytics } from './lib/alloyAnalytics';
import { gptApiRef, GPTClient } from '@telus/plugin-gpt';
import { DBViewer, dbViewerApiRef } from './lib/DBViewerClient';
import {
  mkdocsCreatorApiRef,
  MkDocsCreatorClient,
} from '@telus/plugin-mkdocs-creator';
import {
  teamSkillsApiRef,
  TeamSkillsClient,
} from '@telus/plugin-team-skills-frontend';
import { tdrmApiRef, TDRMClient } from '@telus/plugin-tdrm';
import {
  docsViewerApiRef,
  DocsViewerClient,
} from '@telus/plugin-docs-viewer-frontend';
import {
  ownershipProviderApiRef,
  OwnershipProviderClient,
} from '@telus/frontend-common';
import { tdaApiRef, TdaClient } from '@telus/plugin-td-academy-frontend';
import { iccApiRef, IccClient } from '@telus/plugin-icc-frontend';
import { spocApiRef, SpocClient } from '@telus/plugin-spoc'
import { externalHealthCheckApiRef, ExternalHealthCheckClient } from '@telus/plugin-external-health-check-frontend';
import { riskEngineApiRef, RiskEngineClient } from '@telus/plugin-risk-engine-assessments';
import { landingPageApiRef, LandingPageClient } from '@telus/plugin-aac-template-landing';
import { TascApiClient, tascApiRef } from '@telus/plugin-tasc-frontend';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),

  createApiFactory({
    api: riskEngineApiRef,
    deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef, configApi: configApiRef },
    factory: ({ discoveryApi, identityApi, configApi }) =>
      new RiskEngineClient({ discoveryApi, identityApi, configApi }),
  }),

  createApiFactory({
    api: externalHealthCheckApiRef,
    deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef, configApi: configApiRef },
    factory: ({ discoveryApi, identityApi, configApi }) =>
      new ExternalHealthCheckClient({ discoveryApi, identityApi, configApi }),
  }),

  createApiFactory({
    api: techRadarApiRef,
    deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
    factory: ({ discoveryApi, identityApi }) =>
      new TechRadarClient({ discoveryApi, identityApi }),
  }),

  createApiFactory({
    api: githubAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
      GithubAuth.create({
        discoveryApi,
        oauthRequestApi,
        configApi,
        defaultScopes: ['read:user', 'repo'],
      }),
  }),

  createApiFactory({
    api: graphQlBrowseApiRef,
    deps: { errorApi: errorApiRef, githubAuthApi: githubAuthApiRef },
    factory: ({ errorApi, githubAuthApi }) =>
      GraphQLEndpoints.from([
        GraphQLEndpoints.github({
          id: 'github',
          title: 'GitHub',
          url: 'https://api.github.com/graphql',
          errorApi: errorApi,
          githubAuthApi: githubAuthApi,
        }),
      ]),
  }),

  createApiFactory({
    api: analyticsApiRef,
    deps: {
      configApi: configApiRef,
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef,
      storageApi: storageApiRef,
      fetchApi: fetchApiRef,
    },
    factory: ({}) =>
      MultipleAnalyticsApi.fromApis([AlloyAnalytics.fromConfig()]),
  }),

  createApiFactory({
    api: teamSkillsApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      configApi: configApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ discoveryApi, configApi, identityApi }) =>
      new TeamSkillsClient({
        discoveryApi,
        configApi,
        identityApi,
      }),
  }),
  createApiFactory({
    api: ownershipProviderApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      configApi: configApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ discoveryApi, configApi, identityApi }) =>
      new OwnershipProviderClient({
        discoveryApi,
        configApi,
        identityApi,
      }),
  }),

  createApiFactory({
    api: dbViewerApiRef,
    deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
    factory: ({ discoveryApi, identityApi }) =>
      new DBViewer({ discoveryApi, identityApi }),
  }),

  createApiFactory({
    api: gptApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      configApi: configApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ discoveryApi, configApi, identityApi }) =>
      new GPTClient({
        discoveryApi,
        configApi,
        identityApi,
      }),
  }),

  createApiFactory({
    api: tdaApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, configApi, identityApi }) =>
      new TdaClient({ discoveryApi, configApi, identityApi }),
  }),

  createApiFactory({
    api: mkdocsCreatorApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef,
      configApi: configApiRef,
      githubAuthApi: githubAuthApiRef,
    },
    factory: ({ discoveryApi, identityApi, configApi, githubAuthApi }) =>
      new MkDocsCreatorClient({
        discoveryApi,
        identityApi,
        configApi,
        githubAuthApi,
      }),
  }),

  createApiFactory({
    api: docsViewerApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, identityApi, configApi }) =>
      new DocsViewerClient({
        discoveryApi,
        identityApi,
        configApi,
      }),
  }),

  createApiFactory({
    api: tdrmApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, identityApi, configApi }) =>
      new TDRMClient({ discoveryApi, identityApi, configApi }),
  }),

  createApiFactory({
    api: spocApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, identityApi, configApi }) =>
      new SpocClient({ discoveryApi, identityApi, configApi }),
  }),

  createApiFactory({
    api: iccApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, identityApi, configApi }) =>
      new IccClient({ discoveryApi, identityApi, configApi }),
  }),

  createApiFactory({
    api: tascApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, identityApi, configApi }) =>
      new TascApiClient({ discoveryApi, identityApi, configApi }),
  }),

  createApiFactory({
    api: landingPageApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ discoveryApi, identityApi }) =>
      new LandingPageClient({ discoveryApi, identityApi }),
  }),
];
