import {
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import {
  ApiToolsServiceApiClient,
  apiToolsApiRef,
} from './api/api-tools-service';

import { rootRouteRef, apiFdFormRouteRef } from './routes';

export const apiToolsPlugin = createPlugin({
  id: 'api-tools',
  apis: [
    createApiFactory({
      api: apiToolsApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        identityApi: identityApiRef,
        configApi: configApiRef,
      },
      factory: ({ identityApi, configApi }) =>
        new ApiToolsServiceApiClient({
          identityApi,
          backendBaseUrl: configApi.getString('backend.baseUrl'),
        }),
    }),
  ],
  routes: {
    root: rootRouteRef,
    details: apiFdFormRouteRef,
  },
});

export const ApiToolsPage = apiToolsPlugin.provide(
  createRoutableExtension({
    name: 'ApiToolsPage',
    component: () =>
      import('./components/ApiFdFormComponent').then(m => m.ApiToolsComponent),
    mountPoint: rootRouteRef,
  }),
);

export const ApiFdUpdateCard: () => JSX.Element = apiToolsPlugin.provide(
  createComponentExtension({
    name: 'ApiFdUpdateCard',
    component: {
      lazy: () =>
        import('./components/ApiFDUpdateCardComponent').then(
          m => m.ApiFDUpdateCardComponent,
        ),
    },
  }),
);

export const ApitoolsContextualPage: () => JSX.Element = apiToolsPlugin.provide(
  createComponentExtension({
    name: 'ApitoolsContextualPageComponent',
    component: {
      lazy: () =>
        import('./components/ApitoolsContextualPageComponent').then(
          m => m.ApitoolsContextualPageComponent,
        ),
    },
  }),
);
