import React from 'react';
import { Tab } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { navigateLink } from './navHelpers';
import { useDesktopContext } from './NavBar';

interface LinkTabProps {
  label?: string;
  href?: string;
  className?: any;
  tabIndex?: number;
}

export const LinkTab = (props: LinkTabProps) => {
  const navigate = useNavigate();
  const { setValue } = useDesktopContext();
  return (
    <Tab
      component="a"
      className={props.className}
      tabIndex={props.tabIndex}
      onClick={navigateLink(setValue, navigate)}
      disableRipple
      disableTouchRipple
      disableFocusRipple
      {...props}
    />
  );
};
