import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormGroup,
  IconButton,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { catalogApiRef, useEntity } from '@backstage/plugin-catalog-react';
import { ownershipProviderApiRef } from '@telus/frontend-common';
import { useApi } from '@backstage/core-plugin-api';
import { Autocomplete } from '@material-ui/lab';
import { InputLinks } from './InputLinks';

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

export const EntityDialog = ({ open, onClose }: any) => {
  const classes = useStyles();
  const { entity } = useEntity();
  const catalogApi = useApi(catalogApiRef);
  const [groupEntities, setGroupEntities] = useState<any>([]);
  const [primeUsers, setPrimeUsers] = useState<any>([]);
  const [tags, setTags] = useState<any>([]);
  const [formState, setFormState] = useState<any>({});
  const ownershipProviderApi = useApi(ownershipProviderApiRef);

  const loadEntity = useCallback(
    (name: string) => {
      ownershipProviderApi.getRepositoryEntityByName(name).then((res: any) => {
        setFormState({
          kind: res.response.kind,
          name: res.response.metadata?.name,
          description: res.response.metadata?.description,
          lifecycle: res.response.spec?.lifecycle,
          owner: res.response.spec?.owner,
          managerEmail: res.response.metadata?.annotations?.managerEmail,
          directorEmail: res.response.metadata?.annotations?.directorEmail,
          primeEmail: res.response.metadata?.annotations?.primeEmail,
          vpEmail: res.response.metadata?.annotations?.vpEmail,
          adrLocation:
            res.response.metadata?.annotations?.['backstage.io/adr-location'],
          tags: res.response.metadata?.tags,
        });

        setTags(res.response.metadata?.tags);

        const owner = res.response.spec?.owner;

        catalogApi
          .getEntities({
            filter: {
              kind: ['Group'],
            },
          })
          .then((groups: any) => {
            setGroupEntities(groups.items);
            const group = groups.items.find(
              (item: any) => item.metadata.name === owner,
            );

            const members = group?.spec?.members;

            catalogApi
              .getEntities({
                filter: {
                  kind: ['User'],
                  'metadata.name': members,
                },
              })
              .then((users: any) => {
                setPrimeUsers(users.items);
              });
          });
      });
    },
    [catalogApi, ownershipProviderApi, setFormState],
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const updatedEntity = {
      ...entity,
      kind: formState.kind,
      metadata: {
        ...entity.metadata,
        name: formState.name,
        description: formState.description,
        annotations: {
          ...entity.metadata.annotations,
          managerEmail: formState.managerEmail,
          directorEmail: formState.directorEmail,
          primeEmail: formState.primeEmail,
          vpEmail: formState.vpEmail,
          'backstage.io/adr-location': formState.adrLocation,
        },
        tags: formState.tags,
        links: formState.links,
      },
      spec: {
        ...entity.spec,
        owner: formState.owner,
        lifecycle: formState.lifecycle,
      },
    };

    if (entity?.metadata?.name) {
      ownershipProviderApi.updateRepositoryEntityByName(
        entity.metadata.name,
        updatedEntity,
      );
    }
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    setFormState({
      ...formState,
      [e.target.name]: value,
    });
  };

  const handleOwnerChange = (e: any) => {
    const value = e.target.value;

    const group = groupEntities.find(
      (item: any) => item.metadata.name === value,
    );

    if (!group) {
      return;
    }

    setFormState({
      ...formState,
      owner: group.metadata.name,
      primeEmail: '',
      managerEmail: '',
      directorEmail: '',
      vpEmail: '',
    });

    const members = group?.spec?.members;

    catalogApi
      .getEntities({
        filter: {
          kind: ['User'],
          'metadata.name': members,
        },
      })
      .then((users: any) => {
        setPrimeUsers(users.items);
      });
  };

  const handlePrimeChange = (e: any) => {
    const value = e.target.value;
    const primeUser = primeUsers.find(
      (item: any) => item.spec?.profile.email === value,
    );

    if (!primeUser) {
      return;
    }

    setFormState({
      ...formState,
      primeEmail: primeUser.spec?.profile?.email,
      managerEmail: primeUser.spec?.profile?.manager.email,
      directorEmail: primeUser.spec?.profile?.director.email,
      vpEmail: primeUser.spec?.profile?.vp.email,
    });
  };

  const handleTagChange = (_: any, newValue: any) => {
    if (newValue && newValue.length >= 0) {
      setFormState({
        ...formState,
        tags: [...newValue],
      });
    }
  };

  const handleLinksChange = (links: any) => {
    setFormState({
      ...formState,
      links,
    });
  };

  useEffect(() => {
    if (entity?.metadata?.name) {
      loadEntity(entity?.metadata?.name);
    }
  }, [entity, loadEntity]);

  return (
    <Box>
      <Dialog onClose={onClose} open={open} maxWidth="md" fullWidth>
        <DialogTitle>
          <Typography variant="subtitle1">Entity</Typography>
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <FormGroup style={{ marginBottom: '1rem' }}>
              <InputLabel>Kind</InputLabel>
              <Select
                name="kind"
                value={formState.kind}
                required
                onChange={handleChange}
              >
                <MenuItem value="Component">Component</MenuItem>
                <MenuItem value="Template">Template</MenuItem>
                <MenuItem value="API">API</MenuItem>
                <MenuItem value="Resource">Resource</MenuItem>
                <MenuItem value="System">System</MenuItem>
                <MenuItem value="Domain">Domain</MenuItem>
                <MenuItem value="Location">Location</MenuItem>
              </Select>
            </FormGroup>
            <FormGroup style={{ marginBottom: '1rem' }}>
              <InputLabel>Name</InputLabel>
              <Input
                name="name"
                value={formState.name}
                required
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup style={{ marginBottom: '1rem' }}>
              <InputLabel>Description</InputLabel>
              <Input
                name="description"
                value={formState.description}
                required
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup style={{ marginBottom: '1rem' }}>
              <InputLabel>Lifecycle</InputLabel>
              <Select
                name="lifecycle"
                value={formState.lifecycle}
                required
                onChange={handleChange}
              >
                <MenuItem value="production">Production</MenuItem>
                <MenuItem value="development">Development</MenuItem>
                <MenuItem value="experimental">Experimental</MenuItem>
                <MenuItem value="deprecated">Deprecated</MenuItem>
              </Select>
            </FormGroup>
            <FormGroup style={{ marginBottom: '1rem' }}>
              <InputLabel>Owner</InputLabel>
              <Autocomplete
                options={groupEntities}
                value={groupEntities.find(
                  (item: any) => item.metadata.name === formState.owner,
                )}
                getOptionLabel={(option: any) => option.metadata.name}
                renderInput={params => <TextField {...params} />}
                renderOption={(option: any) => (
                  <React.Fragment>{option.metadata.name}</React.Fragment>
                )}
                onSelect={handleOwnerChange}
              />
            </FormGroup>
            <FormGroup style={{ marginBottom: '1rem' }}>
              <InputLabel>Prime Email</InputLabel>
              <Autocomplete
                options={primeUsers}
                value={primeUsers.find(
                  (item: any) =>
                    item.spec?.profile?.email === formState.primeEmail,
                )}
                getOptionLabel={(option: any) => option.spec?.profile?.email}
                renderInput={params => <TextField {...params} />}
                renderOption={(option: any) => (
                  <React.Fragment>{option.spec?.profile?.email}</React.Fragment>
                )}
                onSelect={handlePrimeChange}
              />
            </FormGroup>
            <FormGroup style={{ marginBottom: '1rem' }}>
              <InputLabel>Manager Email</InputLabel>
              <Input
                name="managerEmail"
                value={formState.managerEmail}
                required
                readOnly
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup style={{ marginBottom: '1rem' }}>
              <InputLabel>Director Email</InputLabel>
              <Input
                name="directorEmail"
                value={formState.directorEmail}
                required
                readOnly
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup style={{ marginBottom: '1rem' }}>
              <InputLabel>VP Email</InputLabel>
              <Input
                name="vpEmail"
                value={formState.vpEmail}
                required
                readOnly
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup style={{ marginBottom: '1rem' }}>
              <InputLabel>Tags</InputLabel>
              <Autocomplete
                multiple
                options={tags}
                defaultValue={formState.tags}
                freeSolo
                renderTags={(value: string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                onChange={handleTagChange}
                renderInput={params => <TextField {...params} />}
              />
            </FormGroup>
            <FormGroup>
              <InputLabel>Links</InputLabel>
              <InputLinks
                value={formState.links}
                onChange={handleLinksChange}
              />
            </FormGroup>
            <FormGroup style={{ marginBottom: '1rem' }}>
              <InputLabel>ADR Location (optional)</InputLabel>
              <Input
                name="adrLocation"
                value={formState.adrLocation}
                onChange={handleChange}
              />
            </FormGroup>
            <Button type="submit" color="primary" variant="contained">
              Update
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
