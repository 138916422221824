import React, { useState } from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import MailIcon from '@material-ui/icons/Mail';
import PeopleIcon from '@material-ui/icons/People';
import TreeIcon from '@material-ui/icons/AccountTree';
import { Link } from '@backstage/core-components';
import { Avatar } from '@backstage/core-components';
import { EntityGroupDialog } from '../EntityEditor/EntityGroupDialog';

export const CardTitle = ({
  title,
  description,
}: {
  title: string | undefined;
  description: string | undefined;
}) => (
  <Box display="flex" flexDirection="row">
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row">
        <Typography
          variant="h5"
          style={{ paddingTop: '0.2rem', paddingRight: '0.5rem' }}
        >
          <PeopleIcon />
        </Typography>
        <Typography variant="h5">{title}</Typography>
      </Box>
      <Typography variant="subtitle2">{description}</Typography>
    </Box>
  </Box>
);

export const EntityGroupProfileCard = () => {
  const { entity } = useEntity<any>();
  const [editorOpenClose, setEditorOpenClose] = useState(false);

  return (
    <Box>
      <EntityGroupDialog
        open={editorOpenClose}
        onClose={() => {
          setEditorOpenClose(false);
        }}
      />
      <Card>
        <CardHeader
          title={
            <CardTitle
              title={entity?.spec?.profile?.name}
              description={entity?.metadata?.description}
            />
          }
          action={
            <IconButton
              onClick={() => {
                setEditorOpenClose(true);
              }}
            >
              <EditIcon />
            </IconButton>
          }
          subheader={undefined}
        />
        <CardContent>
          <Box display="flex" flexDirection="row">
            <Box paddingRight="2rem">
              <Avatar displayName={entity?.spec?.profile?.name} />
            </Box>
            <Box display="flex" flexDirection="column">
              <Box display="flex" flexDirection="row">
                <Typography variant="h4" style={{ paddingRight: '1rem' }}>
                  <MailIcon />
                </Typography>
                <Typography variant="h4">
                  <Link to={`mailto:${entity?.spec?.profile?.email}`}>
                    {entity?.spec?.profile?.email}
                  </Link>
                  <Typography variant="subtitle2">Email</Typography>
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row">
                <Typography variant="h4" style={{ paddingRight: '1rem' }}>
                  <TreeIcon />
                </Typography>
                <Typography variant="h4">
                  <Link
                    to={`/catalog/${
                      entity.metadata.namespace
                    }/group/${entity.spec.parent?.toLowerCase()}`}
                  >
                    {entity.spec.parent?.toLowerCase()}
                  </Link>
                  <Typography variant="subtitle2">Parent Group</Typography>
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row">
                <Typography variant="h4" style={{ paddingRight: '1rem' }}>
                  <PeopleIcon />
                </Typography>
                <Typography variant="h4">
                  {entity?.relations?.filter(
                    (item: any) => item.type === 'parentOf',
                  ).length
                    ? ''
                    : 'None'}
                  {entity?.relations
                    ?.filter((item: any) => item.type === 'parentOf')
                    ?.map((item: any, index: number) => (
                      <React.Fragment key={index}>
                        {index > 0 && ', '}
                        <Link
                          to={`/catalog/${item.target.namespace}/${item.target.kind}/${item.target.name}`}
                        >
                          {item.target.name}
                        </Link>
                      </React.Fragment>
                    ))}
                  <Typography variant="subtitle2">Child Groups</Typography>
                </Typography>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
