import React from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Typography, makeStyles } from '@material-ui/core';
import { useMobileContext } from './MobileNavBar';

const useStyles = makeStyles(theme => ({
  mobileTab: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    fontSize: '16px',
    padding: '16px',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'initial',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
    },
  },
}));

interface MobileNavTabProps {
  title: string;
  index: number;
}

export const MobileNavTab = (props: MobileNavTabProps) => {
  const classes = useStyles();
  const { setDropdown } = useMobileContext();


  return (
    <Typography
      component="a"
      onClick={() => setDropdown(props.index)}
      className={classes.mobileTab}
      tabIndex={0}
    >
      {props.title} <ArrowForwardIcon />
    </Typography>
  );
};
