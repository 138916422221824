import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const iccFrontendPlugin = createPlugin({
  id: 'icc-frontend',
  routes: {
    root: rootRouteRef,
  },
});

export const IccFrontendPage = iccFrontendPlugin.provide(
  createRoutableExtension({
    name: 'IccFrontendPage',
    component: () =>
      import('./components/Main').then(m => m.Main),
    mountPoint: rootRouteRef,
  }),
);

export const IccForgeRockPage = iccFrontendPlugin.provide(
  createRoutableExtension({
    name: 'IccForgeRockPage',
    component: () =>
      import('./components/Resources').then(m => m.ForgeRockPage),
    mountPoint: rootRouteRef,
  }),
);
