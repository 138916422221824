import { Box, makeStyles } from '@material-ui/core';

import React, { createContext, useContext, useEffect, useState } from 'react';
import { AddPluginCard } from './AddPluginCard';
import { ViewPluginCard } from './ViewPluginCard';
import { SelectPluginCard } from './SelectPluginCard';
import { storageApiRef, useApi } from '@backstage/core-plugin-api';

const useStyles = makeStyles(() => ({
  main: {
    minWidth: '510px',
    margin: '16px',
    maxHeight: '100%',
    boxShadow: '0px 8px 4px 0px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '4px',
  },
}));

export type ModeContext = {
  mode: string;
  setMode: (v: string) => void;
};

export const PluginModeContext = createContext<ModeContext>({
  mode: 'add',
  setMode: () => { },
});

export const useModeContext = () => useContext(PluginModeContext);

// export type WidgetContext = {
//   widget: string;
//   setWidget: (v: string) => void;
// };

// export const WidgetPluginContext = createContext<WidgetContext>({
//   widget: 'dynatrace',
//   setWidget: () => {},
// });

// export const useWidgetContext = () => useContext(WidgetPluginContext);

interface PluginCardProps {
  widget: string;
  mode: string;
  position: number;
}

export const PluginCard = (props: PluginCardProps) => {
  const classes = useStyles();
  const [mode, setMode] = useState(props.mode);
  const [widget, setWidget] = useState(props.widget);
  const storageApi = useApi(storageApiRef);

  useEffect(() => {
    const widgetsValue = (storageApi.snapshot('widgets').value as string[]) || [
      '',
    ];
    const copyWidgetsValue = [...widgetsValue];
    const newWidget = copyWidgetsValue[props.position];
    setWidget(newWidget);
  }, [props.widget, props.position, storageApi]);

  return (
    <PluginModeContext.Provider value={{ mode, setMode }}>
      <Box className={classes.main}>
        {mode === 'add' && <AddPluginCard />}
        {mode === 'view' && (
          <ViewPluginCard widget={widget} position={props.position} />
        )}
        {mode === 'select' && <SelectPluginCard />}
      </Box>
    </PluginModeContext.Provider>
  );
};
