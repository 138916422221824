import { 
  configApiRef,
  createApiFactory, 
  createPlugin, 
  createRoutableExtension,
  identityApiRef,
  discoveryApiRef
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

import {
  SecurityMetricsClient,
  securityMetricsApiRef,
} from './clients/security.client';

export const securityMetricsPlugin = createPlugin({
  id: 'security-metrics',
  apis: [
    createApiFactory({
      api: securityMetricsApiRef,
      deps: {
        identityApi: identityApiRef,
        discoveryApi: discoveryApiRef,
        configApi: configApiRef,
      },
      factory: ({ identityApi, discoveryApi, configApi }) =>
        new SecurityMetricsClient({
          identityApi,
          discoveryApi,
          configApi
        }),
    })
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const SecurityMetricsPage = securityMetricsPlugin.provide(
  createRoutableExtension({
    name: 'SecurityMetricsPage',
    component: () =>
      import('./components/SecurityComponent').then(m => m.SecurityComponent),
    mountPoint: rootRouteRef,
  }),
);
