import { FrontendClient } from '@telus/frontend-common';
import {
  ConfigApi,
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { ApiRef, createApiRef } from '@backstage/core-plugin-api';
import { Cohort, CohortMentee, Match, Mentor } from '../utils/types';
import { ActiveCohortInfos } from '../utils/types';

/**
 * {@link @backstage/core-plugin-api#ApiRef}
 */

export const teamSkillsApiRef: ApiRef<TeamSkillsApi> =
  createApiRef<TeamSkillsApi>({
    id: 'plugin.teamskills.service',
  });

export interface TeamSkillsApi {
  createMentor: (data: any) => Promise<Mentor>;
  searchMentors: (query: any) => Promise<any>;
  getMentors: () => Promise<any>;
  getMentor: (id: string) => Promise<any>;
  deleteMentor: (id: string) => Promise<any>;
  updateMentor: (id: string, data: any) => Promise<any>;
  uploadPicture: (id: string, data: any) => Promise<any>;
  uploadAttachments: (id: string, data: any) => Promise<any>;
  createMentee: (data: any) => Promise<any>;
  searchMentees: (query: any) => Promise<any>;
  getMentees: () => Promise<any>;
  getMentee: (id: string) => Promise<any>;
  updateMentee: (id: string, data: any) => Promise<any>;
  uploadMenteePicture: (id: string, data: any) => Promise<any>;
  deleteMentee: (id: string) => Promise<any>;
  getProfile: (userEmail: string) => Promise<any>;
  hasAdminAccess: () => Promise<any>;
  createCohort: (name: string) => Promise<Cohort>;
  getAllCohorts: () => Promise<Cohort[]>;
  getActiveCohort: () => Promise<Cohort>;
  getActiveCohortInfo: () => Promise<ActiveCohortInfos>;
  setMentorSignupState: (isOpen: boolean) => Promise<void>;
  setMenteeSignupState: (isOpen: boolean) => Promise<void>;
  mentorSignup: (mentorId: string) => Promise<any>;
  menteeSignup: (menteeId: string, mentorSelection: string[]) => Promise<any>;
  saveMatches: (matches: Match[]) => Promise<any>;
  currentCohortMentors: () => Promise<Mentor[]>;
  currentCohortMentees: () => Promise<CohortMentee[]>;
  removeMentorFromCurrentCohort: (mentorId: string) => Promise<void>;
  removeMenteeFromCurrentCohort: (menteeId: string) => Promise<void>;
  isMentorPartOfActiveCohort: (mentorId: string) => Promise<any>;
  getMenteeMentorsSelection: (menteeId: string) => Promise<string[]>;
  createMockMenteeProfiles: () => Promise<any>;
  createMockMentorProfiles: () => Promise<any>;
  createMockMentorSelections: () => Promise<any>;
}

type deps = {
  discoveryApi: DiscoveryApi;
  configApi: ConfigApi;
  identityApi: IdentityApi;
};

export class TeamSkillsClient extends FrontendClient implements TeamSkillsApi {
  protected readonly configApi: ConfigApi;

  constructor(deps: deps) {
    super({
      discoveryApi: deps.discoveryApi,
      identityApi: deps.identityApi,
      defaultPlugin: 'team-skills',
    });

    this.configApi = deps.configApi;
  }

  async createMentor(data: any) {
    return await this.postRequired('/mentors', data) as Mentor;
  }
  async updateMentor(id: string, data: any) {
    return await this.putRequired(`/mentors/${id}`, data);
  }
  async searchMentors(query: any) {
    return await this.postRequired('/mentors/search', query);
  }
  async getMentors() {
    return await this.getRequired('/mentors');
  }
  async getMentor(id: string) {
    return await this.getRequired(`/mentors/${id}`);
  }

  async deleteMentor(id: string) {
    return await this.deleteRequired(`/mentors/${id}`);
  }

  async uploadPicture(id: string, data: any) {
    const url = await this.getBaseUrl(`/mentors/${id}/avatars/upload`);
    const response: any = await this.axiosClient.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response;
  }

  async uploadAttachments(id: string, data: any) {
    const url = await this.getBaseUrl(`/mentors/${id}/attachments/upload`);
    const response: any = await this.axiosClient.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response;
  }

  async createMentee(data: any) {
    return await this.postRequired('/mentees', data);
  }
  async searchMentees(query: any) {
    return await this.postRequired('/mentees/search', query);
  }
  async getMentees() {
    return await this.getRequired('/mentees');
  }
  async getMentee(id: string) {
    return await this.getRequired(`/mentees/${id}`);
  }
  async getMenteeOptions() {
    return await this.getRequired(`/mentees/options`);
  }
  async updateMentee(id: string, data: any) {
    return await this.putRequired(`/mentees/${id}`, data);
  }
  async uploadMenteePicture(id: string, data: any) {
    const url = await this.getBaseUrl(`/mentees/${id}/avatars/upload`);
    const response: any = await this.axiosClient.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response;
  }
  async deleteMentee(id: string) {
    return await this.deleteRequired(`/mentees/${id}`);
  }

  async getProfile(userEmail: string) {
    return await this.postRequired('/profiles/me', { userEmail });
  }

  async getAdmins() {
    return await this.getRequired('/admins');
  }

  async hasAdminAccess() {
    return await this.getRequired('/hasAdminAccess');
  }

  async createCohort(name: string): Promise<Cohort> {
    return await this.postRequired('/cohorts', { name }) as Cohort;
  }

  async getAllCohorts(): Promise<Cohort[]> {
    return await this.getRequired('/cohorts');
  }

  async getActiveCohort(): Promise<Cohort> {
    return await this.getRequired('/cohorts/active-cohort') as Cohort;
  }

  async getActiveCohortInfo(): Promise<ActiveCohortInfos> {
    return await this.getRequired('/cohorts/active-cohort-info') as ActiveCohortInfos
  }

  async setMentorSignupState(isOpen: boolean): Promise<void> {
    return await this.postRequired('/cohorts/update-active-cohort/mentor-signup-status', { isOpen });
  }

  async setMenteeSignupState(isOpen: boolean): Promise<void> {
    return await this.postRequired('/cohorts/update-active-cohort/mentee-signup-status', { isOpen });
  }

  async mentorSignup(mentorId: string): Promise<any> {
    return await this.postRequired('/cohorts/mentor-signup', { mentorId });
  }

  async menteeSignup(menteeId: string, mentorSelection: string[]): Promise<any> {
    return await this.postRequired('/cohorts/mentee-signup', { menteeId, mentorSelection });
  }

  async saveMatches(matches: Match[]): Promise<any> {
    return await this.postRequired('/cohorts/save-matches', { matches })
  }

  async currentCohortMentors(): Promise<Mentor[]> {
    return await this.getRequired('/cohorts/mentors') as Mentor[];
  }

  async currentCohortMentees(): Promise<CohortMentee[]> {
    return await this.getRequired('/cohorts/mentees') as CohortMentee[];
  }

  async removeMentorFromCurrentCohort(mentorId: string): Promise<void> {
    return await this.deleteRequired(`/cohorts/mentors/${mentorId}`);
  }

  async removeMenteeFromCurrentCohort(menteeId: string): Promise<void> {
    return await this.deleteRequired(`/cohorts/mentees/${menteeId}`);
  }

  async isMentorPartOfActiveCohort(mentorId: string): Promise<any> {
    return await this.getRequired(`/cohorts/has-mentor-signed-up/${mentorId}`);
  }

  async getMenteeMentorsSelection(menteeId: string): Promise<string[]> {
    return await this.getRequired(`/cohorts/mentee-mentor-selection/${menteeId}`) as string[];
  }
  async createMockMenteeProfiles(): Promise<any> {
    return await this.getRequired('/mentees/mock-batch');
  }

  async createMockMentorProfiles(): Promise<any> {
    return await this.getRequired('/mentors/mock-batch');
  }

  async createMockMentorSelections(): Promise<any> {
    return await this.getRequired('/cohorts/mock-batch-mentee-signup');
  }

}