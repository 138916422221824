import { storageApiRef, useApi } from '@backstage/core-plugin-api';
import React, { createContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

type LangContextType = {
  lang: 'en' | 'fr';
  switchLang: (newLang: 'en' | 'fr') => void;
};

export const LangContext = createContext<LangContextType>({} as LangContextType);

export const LangProvider = ({ children }: { children: React.ReactNode }) => {
  const storageApi = useApi(storageApiRef);
  const urlLang = new URLSearchParams(window.location.search).get('lang');
  const isUrlLang = urlLang === 'fr' || urlLang === 'en';
  const storageLang = storageApi.snapshot('lang').value as string;
  const isStorageLang = storageLang === 'fr' || storageLang === 'en';
  const [lang, setLang] = useState<'en' | 'fr'>('en');
  const location = useLocation();

  const setInitialLang = () => {
    if (isStorageLang) {
      setLang(storageLang as 'en' | 'fr');
    } else if (isUrlLang) {
      setLang(urlLang as 'en' | 'fr');
    }
  }

  const setInitialLangRef = useRef(setInitialLang);

  useEffect(() => {
    setInitialLangRef.current();
  }, [setInitialLangRef])

  const switchLang = (newLang: 'en' | 'fr') => {
    setLang(newLang);
    storageApi.set('lang', newLang);
  }

  const switchLangRef = useRef(switchLang);

  const memo = useMemo(() => {
    return {
      lang,
      switchLang: switchLangRef.current,
    };
  }, [lang, switchLangRef]);

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('lang', lang);
    window.history.replaceState({}, '', currentUrl.href);
  }, [location, lang])

  return (
    <LangContext.Provider value={memo}>
      {children}
    </LangContext.Provider>
  );
}