import { FrontendClient } from '@telus/frontend-common';
import {
  ConfigApi,
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { ApiRef, createApiRef } from '@backstage/core-plugin-api';

/**
 * {@link @backstage/core-plugin-api#ApiRef}
 */

export const riskEngineApiRef: ApiRef<RiskEngineApi> =
  createApiRef<RiskEngineApi>({
    id: 'plugin.riskengine.service',
  });

export interface RiskEngineApi {
  getAssessments(projectName: string, pageSize: number, page: number): Promise<any>;
  getAssessmentById(assessmentId: string): Promise<any>;
  getEmbargoes(): Promise<any>;
}

type deps = {
  discoveryApi: DiscoveryApi;
  configApi: ConfigApi;
  identityApi: IdentityApi;
};

export class RiskEngineClient extends FrontendClient implements RiskEngineApi {
  protected readonly configApi: ConfigApi;

  constructor(deps: deps) {
    super({
      discoveryApi: deps.discoveryApi,
      identityApi: deps.identityApi,
      defaultPlugin: 'risk-engine',
    });

    this.configApi = deps.configApi;
  }

  async getAssessments(projectName: string, pageSize: number, page: number) {
    return await this.getRequired(`/assessments?project=${projectName}&perPage=${pageSize}&currentPage=${
      page + 1
    }&orderBy=createdAt&orderDirection=desc`);
  }

  async getAssessmentById(assessmentId: string) {
    return await this.getRequired(`/assessments/${assessmentId}`);
  }

  async getEmbargoes() {
    return await this.getRequired('/embargoes');
  }
}
