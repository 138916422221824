export const t: Record<string, any> = {
  externalHealthCheck: {
    en: 'External Health Check',
    fr: 'Vérification des status externes',
  },
  service: {
    en: 'Service',
    fr: 'Service',
  },
  status: {
    en: 'Status',
    fr: 'Status',
  },
  message: {
    en: 'Message',
    fr: 'Message',
  },
  updatedAt: {
    en: 'Updated at',
    fr: 'Mis à jour le',
  },
  serviceStatus: {
    en: 'Service status',
    fr: 'Status du service',
  },
  serviceStatusMessage: {
    en: 'This table shows the status of external services that are used by Telus',
    fr: 'Ce tableau montre le status des services externes utilisés par Telus',
  },

}