import { FrontendClient } from '@telus/frontend-common';
import {
  ConfigApi,
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { ApiRef, createApiRef } from '@backstage/core-plugin-api';
import { Editor, Playlist, ServiceResponse, Video, VideoComment } from '../types';

/**
 * {@link @backstage/core-plugin-api#ApiRef}
 */

export const tdaApiRef: ApiRef<TdaApi> = createApiRef<TdaApi>({
  id: 'plugin.td-academy.service',
});

export interface TdaApi {
  readPlaylist(playlistId: string): Promise<ServiceResponse<Playlist>>;
  createPlaylist(playlist: Playlist): Promise<ServiceResponse<Playlist>>;
  deletePlaylist(dataName: string, editor: Editor): Promise<any>;
  updatePlaylist(playlist: Playlist, editor: Editor): Promise<ServiceResponse<Playlist>>;
  getAllPlaylists(): Promise<ServiceResponse<Playlist[]>>;
  addVideoToPlaylist(
    playlistId: string,
    video: Video,
    editor: Editor,
  ): Promise<ServiceResponse<Video>>;
  getEditablePlaylists(editor: Editor): Promise<ServiceResponse<Playlist[]>>;
  editVideoInPlaylist(
    playlistId: string,
    video: Video,
    editor: Editor,
  ): Promise<ServiceResponse<Video>>;
  deleteVideoFromPlaylist(
    playlistId: string,
    videoId: string,
    editor: Editor,
  ): Promise<any>;
  addCommentToVideo(
    videoId: string,
    comment: VideoComment,
  ): Promise<ServiceResponse<VideoComment>>;
  getCommentsForVideo(videoId: string): Promise<ServiceResponse<VideoComment[]>>;
  deleteCommentForVideo(videoId: string, commentId: string, editor: Editor): Promise<ServiceResponse<any>>
}

type deps = {
  discoveryApi: DiscoveryApi;
  configApi: ConfigApi;
  identityApi: IdentityApi;
};

export class TdaClient extends FrontendClient implements TdaApi {
  protected readonly configApi: ConfigApi;

  constructor(deps: deps) {
    super({
      discoveryApi: deps.discoveryApi,
      identityApi: deps.identityApi,
      defaultPlugin: 'td-academy',
    });

    this.configApi = deps.configApi;
  }

  async getAllPlaylists() {
    return await this.getRequired(`/tda-playlist-catalog`) as ServiceResponse<Playlist[]>;
  }

  async readPlaylist(playlistId: string) {
    return await this.getRequired(`/tda-playlist-catalog/${playlistId}`) as ServiceResponse<Playlist>;
  }

  async createPlaylist(playlist: Playlist) {
    return await this.postRequired('/tda-playlist-catalog/create', playlist) as ServiceResponse<Playlist>;
  }

  async deletePlaylist(playlistId: string, editor: Editor) {
    return this.postRequired(`/tda-playlist-catalog/${playlistId}/delete`, {
      editor,
    });
  }

  async addVideoToPlaylist(playlistId: string, video: Video, editor: Editor) {
    const payload = {
      video,
      editor,
    };
    return await this.postRequired(
      `/tda-playlist-catalog/${playlistId}/add-video`,
      payload,
    ) as ServiceResponse<Video>;
  }

  async updatePlaylist(playlist: Playlist, editor: Editor) {
    const payload = {
      playlist,
      editor,
    };
    return await this.postRequired(`/tda-playlist-catalog/update`, payload) as ServiceResponse<Playlist>;
  }

  async getEditablePlaylists(editor: Editor) {
    return await this.postRequired(`/tda-playlist-catalog/editable-playlists`, {
      editor,
    }) as ServiceResponse<Playlist[]>;
  }

  async editVideoInPlaylist(playlistId: string, video: Video, editor: Editor) {
    const payload = {
      video,
      editor,
    };
    return await this.postRequired(
      `/tda-playlist-catalog/${playlistId}/edit-video`,
      payload,
    ) as ServiceResponse<Video>;
  }

  async deleteVideoFromPlaylist(
    playlistId: string,
    videoId: string,
    editor: Editor,
  ) {
    const payload = {
      editor,
    };
    return this.postRequired(
      `/tda-playlist-catalog/${playlistId}/delete-video/${videoId}`,
      payload,
    );
  }

  async addCommentToVideo(videoId: string, comment: VideoComment): Promise<ServiceResponse<VideoComment>> {
    return await this.postRequired(
      `/tda-playlist-catalog/${videoId}/add-comment`,
      { comment },
    ) as ServiceResponse<VideoComment>;
  }

  async getCommentsForVideo(videoId: string): Promise<ServiceResponse<VideoComment[]>> {
    return await this.getRequired(`/tda-playlist-catalog/${videoId}/comments`) as ServiceResponse<VideoComment[]>;
  }

  async deleteCommentForVideo(videoId: string, commentId: string, editor: Editor): Promise<ServiceResponse<any>> {
    return await this.postRequired(
      `/tda-playlist-catalog/${videoId}/delete-comment/${commentId}`,
      { editor },
    ) as ServiceResponse<any>;
  }
}
