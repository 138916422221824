import { Box, IconButton, Typography, makeStyles } from '@material-ui/core';

import React, { useContext } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { useModeContext } from './PluginCard';
import { LangContext } from '@telus/frontend-common';
import { t } from '../../translations';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '128px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const AddPluginCard = () => {
  const classes = useStyles();
  const { setMode } = useModeContext();
  const { lang } = useContext(LangContext);

  const handleClick = () => {
    setMode('select');
  };
  return (
    <Box className={classes.root} onClick={handleClick}>
      <IconButton aria-label="add application" onClick={handleClick}>
        <AddIcon fontSize="large" />
      </IconButton>
      <Typography variant="body1" color="textPrimary">
        {t.addApp[lang]}
      </Typography>
    </Box>
  );
};
