import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Chip,
  TableContainer,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useApi } from '@backstage/core-plugin-api';
import { dynatraceApiRef } from '../../api';
import {
  StatusError,
  StatusOK,
  Table,
  TableColumn,
} from '@backstage/core-components';
import { LangContext } from '@telus/frontend-common';
import { t } from '../../translations';

const useStyles = makeStyles((theme: any) => ({
  column: {
    [theme.breakpoints.down('lg')]: {
      width: '5rem',
    },
  },
}));

export const DynatraceEntitiesWidget = () => {
  const dynatraceApi = useApi(dynatraceApiRef);
  const [dynatraceEntities, setDynatraceEntities] = useState<any>({});
  const [filteredEntities, setFilteredEntities] = useState<any[]>([]);
  const { lang } = useContext(LangContext);
  const classes = useStyles();

  const [columns, setColumns] = useState<TableColumn[]>([]);

  const updateColumns = (newLang: 'en' | 'fr') => {
    setColumns([
      {
        title: t.name[newLang],
        type: 'string',
        render: (entity: any) => (
          <Typography variant="body2">
            {entity.hasOpenIssues ? <StatusError /> : <StatusOK />}
            {entity.name}
          </Typography>
        ),
      },
      {
        title: t.reason[newLang],
        type: 'string',
        render: (entity: any) => (
          <Typography className={classes.column} variant="body2">
            {entity.problems.find((item: any) => item.status === 'OPEN')?.title ||
              entity.problems[0]?.title}
          </Typography>
        ),
      },
      {
        title: t.type[newLang],
        type: 'string',
        render: (entity: any) => (
          <Chip className={classes.column} label={entity.entityId.type} />
        ),
      },
      {
        title: t.startTime[newLang],
        type: 'string',
        render: (entity: any) => (
          <Typography className={classes.column} variant="body2">
            {new Date(
              entity.problems.find((item: any) => item.status === 'OPEN')
                ?.startTime || entity.problems[0]?.startTime,
            ).toLocaleDateString(`${newLang}-CA`, {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
            })}
          </Typography>
        ),
      },
    ]);
  }

  const updateColumnsRef = React.useRef(updateColumns);

  useEffect(() => {
    updateColumnsRef.current(lang)
  }, [lang, updateColumnsRef]);

  const loadProblems = useCallback(
    (query: any) => {
      dynatraceApi
        .getProblems(query)
        .then(data => {
          const entities = data.problems.reduce((acc: any, problem: any) => {
            problem.affectedEntities.map((entity: any) => {
              if (!acc[entity.entityId.id]) {
                acc[entity.entityId.id] = {
                  ...entity,
                };
              }

              if (problem.status === 'OPEN') {
                acc[entity.entityId.id].hasOpenIssues = true;
              }

              if (acc[entity.entityId.id].problems) {
                acc[entity.entityId.id].problems = [
                  ...acc[entity.entityId.id].problems,
                  problem,
                ];
              } else {
                acc[entity.entityId.id].problems = [problem];
              }
            });

            return acc;
          }, {});

          setDynatraceEntities((prev: any) => {
            Object.keys(entities).map((id: any) => {
              if (prev[id] && prev[id].hasOpenIssues) {
                entities[id].hasOpenIssues = true;
              }

              if (prev[id]) {
                if (prev[id].problems) {
                  entities[id].problems = [
                    ...prev[id].problems,
                    ...entities[id].problems,
                  ];
                }
              }
            });

            return { ...prev, ...entities };
          });

          return data.nextPageKey;
        })
        .then(nextPageKey => {
          if (nextPageKey) {
            loadProblems({ nextPageKey });
          }
        })
        .catch(_ => { });
    },
    [dynatraceApi, setDynatraceEntities],
  );

  useEffect(() => {
    loadProblems({});
  }, [loadProblems]);

  useEffect(() => {
    const entities = Object.values(dynatraceEntities).sort(
      (a: any, b: any) => a.hasOpenIssues && a.name.localeCompare(b.name),
    );

    setFilteredEntities(entities);
  }, [dynatraceEntities]);

  return (
    <TableContainer >
      <Table
        title={t.widgetTitle[lang]}
        subtitle={t.widgetSubtitle[lang]}
        style={{ maxHeight: '40rem', width: '100%' }}
        options={{ search: false, paging: false }}
        columns={columns}
        data={filteredEntities}
        onRowClick={_event => {
          window.location.href = '/dynatrace-monitor/problems';
        }}
      />
    </TableContainer>
  );
};
