import { FrontendClient } from '@telus/frontend-common'
import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api'
import { ApiRef, createApiRef } from '@backstage/core-plugin-api'

export interface DBViewerApi {
  load: () => Promise<any>
}

type deps = {
  discoveryApi: DiscoveryApi
  identityApi: IdentityApi
}

/**
 * {@link @backstage/core-plugin-api#ApiRef} for the {@link DBViewerApi}
 */
export const dbViewerApiRef: ApiRef<DBViewerApi> = createApiRef<DBViewerApi>({
  id: 'plugin.db-viewer.service',
})

export class DBViewer extends FrontendClient implements DBViewerApi {
  constructor(deps: deps) {
    super({
      ...deps,
      defaultPlugin: 'db-viewer',
    })
  }

  async load() {
    const data = await super.getRequired('/check')
    return data
  }
}