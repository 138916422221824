import '@backstage/cli/asset-types';
import React from 'react';
// @ts-ignore
import { createRoot } from 'react-dom/client';
import App from './App';
import * as Sentry from "@sentry/react";

let environment = 'development'
let tracesSampleRate = 0  // no need to do trace in development
let replaysSessionSampleRate = 1.0

// in production
if (window.location.origin === 'https://simplify.telus.com') {
  environment = 'production'
  tracesSampleRate = 0.05           // 5%
  replaysSessionSampleRate = 0.025  // 2.5%
}
Sentry.init({
  dsn: "https://54f5257cef9233d748f6319ada570928@o4506435926753280.ingest.sentry.io/4506435959062528",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https://simplify.telus.com/", "https://simplify.developers.telus.digital/"],
    }),
    new Sentry.BrowserProfilingIntegration(),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment,
  // Performance Monitoring
  tracesSampleRate, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);