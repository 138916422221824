import React from 'react';
import { NavigateFunction } from 'react-router';
import { LeftCardLink } from './LeftCard';
import { MobileNavLink } from './mobileNav/MobileNavLink';
import { RightCard } from './RightCard';
import { MobileNavCard } from './mobileNav/MobileNavCard';
import { NavTabSrcTypes } from './types';

export const navigateLink = (
  setDropdown: Function,
  navigate: NavigateFunction,
) => {
  const clickHandler = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setDropdown(false);
    const path = event.currentTarget.href.split('/');
    navigate(`/${path[3]}`);
  };
  return clickHandler;
};

export const getNavLinks = (type: string, navTabSrc: NavTabSrcTypes, mobNav?: boolean) => {
  if (!navTabSrc[type].tabPanel) {
    return null;
  }
  const navLinks:
    | {
      [key: string]: {
        title: string;
        href: string;
      };
    }
    | undefined = navTabSrc[type].tabPanel?.leftCard.links;
  if (!navLinks) {
    return null;
  }
  const results = [];
  for (const [key, value] of Object.entries(navLinks)) {
    if (mobNav) {
      results.push(
        <MobileNavLink key={key} title={value.title} link={value.href} />,
      );
    } else {
      results.push(
        <LeftCardLink key={key} title={value.title} link={value.href} />,
      );
    }
  }
  return results;
};

export const getAdCards = (type: string, navTabSrc: NavTabSrcTypes, mobNav?: boolean) => {
  if (!navTabSrc[type].tabPanel) {
    return null;
  }

  const navCards:
    | {
      [key: string]: {
        title: string;
        href: string;
        content: string;
        linkTitle: string;
      };
    }
    | undefined = navTabSrc[type].tabPanel?.rightCard;

  if (!navCards) {
    return null;
  }

  const results = [];
  for (const [key, value] of Object.entries(navCards)) {
    if (mobNav) {
      results.push(
        <MobileNavCard
          key={key}
          title={value.title}
          content={value.content}
          link={value.href}
          linkTitle={value.linkTitle}
        />,
      );
    } else {
      results.push(
        <RightCard
          key={key}
          title={value.title}
          content={value.content}
          link={value.href}
          linkTitle={value.linkTitle}
        />
      );
    }
  }
  return results;
};
